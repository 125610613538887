import {
  DocNode,
  DocNodeBlock, DocNodeCopyText,
  DocNodeHR, DocNodeImage,
  DocNodeInline,
  DocNodeLink,
  DocNodePassword,
  DocNodeText
} from "@/myonly.notes/DocNode";
import { detectUrls } from "@/lib/UrlDetector";

type Transformer = (ct: MdContext, source: string, next: (cxt: MdContext, source: string) => Generator<DocNode>) => Generator<DocNode>;

class MdContext {
  indents = [0];
  respectNewlines = false

  constructor(params: Partial<MdContext>) {
    Object.assign(this, params);
  }

  get currentIndent(): number {
    return this.indents[0];
  }

  listLevel = 0;
}

let mdStack = function* (ct: MdContext, text: string): Generator<DocNode> {
  yield new DocNodeText({ text });
}

function addTransformer(t: Transformer) {
  const old = mdStack;
  mdStack = function* (ct: MdContext, source: string) {
    yield* t(ct, source, old);
  }
}

type NodeGenerator = (cxt: MdContext, source: string) => Generator<DocNode>

function addRegexpTransformer(
  rePre: string,
  rePost: string,
  generator: (ct: MdContext, m: RegExpMatchArray, next: NodeGenerator) => Generator<DocNode>
) {
  addTransformer(function* (ct, text, next) {
    let position = 0;
    for (const m of text.matchAll(new RegExp(rePre + ".*?" + rePost, "g"))) {
      if (m.index === undefined) throw Error("unsupported string.matchAll: empty index");
      if (m.index > position)
        yield* next(ct, text.slice(position, m.index));
      yield* generator(ct, m, next);
      position = m.index + m[0].length;
    }
    if (position < text.length) {
      yield* next(ct, text.substr(position));
    }
  });
}

addRegexpTransformer("`[^`]", "[^`]`", function* (ct, m, next) {
  yield new DocNodeInline().addClass("doc-node-code-inline")
    .append(...next(ct, m[0].substr(1, m[0].length - 2)));
});

addRegexpTransformer("_[^_]", "[^_]_", function* (ct, m, next) {
  yield new DocNodeInline().addClass("doc-node-italic")
    .append(...next(ct, m[0].substr(1, m[0].length - 2)));
});

addRegexpTransformer("__[^_]", "[^_]__", function* (ct, m, next) {
  yield new DocNodeInline().addClass("doc-node-bold")
    .append(...next(ct, m[0].substr(2, m[0].length - 4)));
});

addRegexpTransformer("___[^_]", "[^_]___", function* (ct, m, next) {
  yield new DocNodeInline().addClass("doc-node-bold", "doc-node-italic")
    .append(...next(ct, m[0].substr(3, m[0].length - 6)));
});

addRegexpTransformer("`[^`]", "[^`]`", function* (ct, m, next) {
  yield new DocNodeCopyText({ text: m[0].substr(1, m[0].length - 2) })
});

addRegexpTransformer("``[^`]", "[^`]``", function* (ct, m, next) {
  yield new DocNodePassword({ text: m[0].substr(2, m[0].length - 4) })
});

// Parse inline links
addTransformer(function* (ct, source, next) {
  for (const part of detectUrls(source)) {
    if (part.isUrl) {
      if (!part.text) {
        console.error("BAD LINK: ", part);
        throw new Error("BAD LINK: " + part.text);
      }
      yield new DocNodeLink({ href: part.text });
    } else
      yield* next(ct, part.text);
  }
});

addTransformer(function* (ct, source, next) {
  let position = 0;
  for (const m of source.matchAll(new RegExp(/!\[(.*)]\((.+)\)/g ))) {
    if (m.index === undefined) throw Error("unsupported string.matchAll: empty index");
    if (m.index > position)
      yield* next(ct, source.slice(position, m.index));
    yield new DocNodeImage({src: m[2], title: m[1]})
    position = m.index + m[0].length;
  }
  if (position < source.length) {
    yield* next(ct, source.substr(position));
  }
});

addTransformer(function* (ct, source, next) {
  const i = source.indexOf(" ");
  if (i < 0) {
    if (source.match(/^-{3,}\s?$/))
      yield new DocNodeHR();
    else
      yield new DocNodeBlock().append(...next(ct, source));
  } else {
    const first = source.slice(0, i);
    const rest = source.slice(i);
    switch (first) {
      case "#":
      case "##":
      case "###":
      case "####":
      case "#####":
        const block: DocNode = new DocNodeBlock().addClass(`h${first.length}`)
        yield block.append(...next(ct, rest));
        break;
      default:
        yield new DocNodeBlock().append(...next(ct, source));
    }
  }
});

// parse paragraphs (mltilines)
addTransformer(function* (ct, source, next) {
  if (ct.respectNewlines) {
    let lastWasEmpty = false;
    let firstLines = true;
    for (const s of source.split("\n")) {
      if (s.trim() == "") {
        if (firstLines)
          continue;
        if (!lastWasEmpty) {
          lastWasEmpty = true;
          continue;
        }
        lastWasEmpty = true;
      } else
        lastWasEmpty = false;

      yield* next(ct, s);
    }
  } else {
    let p = "";
    let first = true;
    for (const line of source.split(/\n/g)) {
      if (line.trim() == "") {
        if (p != "") {
          yield* next(ct, p);
          p = "";
        } else if (!first)
          yield new DocNodeBlock();
        else first = false;
      } else p += line + "\n";
    }
    if (p != "") yield* next(ct, p)
  }
});

export function parseMarkdown(md: string, respectNewlines = false): DocNode {
  const result = new DocNodeBlock();
  const ct = new MdContext({ respectNewlines });
  for (const x of mdStack(ct, md.trimEnd())) result.append(x);
  return result;
}
