

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DocNode } from "../myonly.notes/DocNode";
import { errorToast } from "@/lib/toasts";
import { LegalDocs } from "@/docs/LegalDocs";
import { parseMarkdown } from "@/myonly.notes/mdparser";
import CardNode from "@/components/CardNode.vue";
import { Passwords } from "uparsecjs";
import { ConsentDoc } from "@/docs/ConsentDoc";

@Component([CardNode])
export default class Consent extends Vue {
  @Prop() documentId!: string;
  @Prop({ default: false }) canReject!: boolean;
  @Prop({ default: true }) show!: boolean;

  doc: ConsentDoc | null = null;
  node: DocNode | null = null;
  modalId = Passwords.randomId(17);

  rejected = false

  reject() {
    if (this.canReject) {
      this.$emit("noConsent", { document: this.documentId });
      this.$bvModal.hide(this.modalId);
    } else
      this.rejected = true
  }

  accept() {
    if (this.doc) {
      this.doc.accept();
      this.doc = null;
      this.node = null;
      this.$bvModal.hide(this.modalId);
    }
  }

  @Watch("show", { immediate: true })
  onShowChanged() {
    if (this.show)
      this.$nextTick(() => this.$bvModal.show(this.modalId));
    else
      this.$nextTick(() => this.$bvModal.hide(this.modalId));
  }

  @Watch("documentId", { immediate: true })
  onDocChanged() {
    const doc = LegalDocs[this.documentId];
    if (!doc)
      errorToast(this, "document not found: " + this.documentId);
    else {
      if (!doc.isAccepted) {
        this.doc = doc;
        this.node = parseMarkdown(doc.text)
        console.log("W SHOW " + this.modalId);
      }
    }
  }
}
