import {
  Boss,
  bytesToText,
  decode64,
  randomBytes,
  SHAStringType,
  SymmetricKey, textToBytes
} from "unicrypto";
import { pbkdf2 } from "../../node_modules_old/unicrypto";
import { concatenateBinary, equalArrays } from "uparsecjs";

export enum HashType {
  SHA1,
  SHA256,
  SHA512,
  SHA3_256,
  SHA3_384,
  SHA3_512
}

export function toShaStringType(t: HashType): SHAStringType {
  switch (t) {
    case HashType.SHA256:
      return "sha256";
    case HashType.SHA512:
      return "sha512";
    case HashType.SHA3_256:
      return "sha3_256";
    case HashType.SHA3_384:
      return "sha3_384";
    case HashType.SHA3_512:
      return "sha3_512";
    case HashType.SHA1:
      return "sha1"
    default:
      throw new Error(`invalid hash type: ${t}`)
  }
}

export class ZtError extends Error {
  constructor(message = "ZeText error") {
    super(message);
  }
}

export class InvalidPasswordError extends ZtError {
}

export class InvalidFormatError extends ZtError {
  constructor(message: string) {
    super(message);
  }
}

class ZtInfo {

  /**
   * Create ztext info structure
   * @param rounds
   * @param salt
   * @param hashType
   * @param keyId expected keyid if known. leave to undefined to generate new key, in this
   *        case it will be set by deriveKey
   */
    public readonly rounds: number = 100;
    public readonly salt: Uint8Array = randomBytes(32);
    public readonly hashType: HashType = HashType.SHA3_384;
    public keyId?: Uint8Array;

  constructor(attrs: Partial<ZtInfo>) {
      Object.assign(this,attrs)
  }

  /**
   * Derove new key from a given password. If the [[keyId]] is undefined, it will be
   * filled with a generated key, otherwise, generated key will b echecked agains it
   * and {@link InvalidPasswordError} will be thrown on no match.
   * @param password
   */
  async deriveKey(password: string): Promise<SymmetricKey> {
    const data = await pbkdf2(toShaStringType(this.hashType), {
      keyLength: 64,
      rounds: this.rounds, salt: this.salt, password
    })
    const generatedId = data.slice(32, 64);
    if (this.keyId !== undefined) {
      if (!equalArrays(generatedId, this.keyId)) throw new InvalidPasswordError();
    } else
      this.keyId = generatedId
    return new SymmetricKey({ keyBytes: data.slice(0, 32) });
  }

  /**
   * Generate (derive) new key from the password using specified key and rounds, and
   * return ZtInfo instance and a key.
   * @param password
   * @param rounds
   * @param hashType
   */
  static async deriveFromPassword(
    password: string,
    rounds = 20000,
    hashType: HashType = HashType.SHA3_384
  ): Promise<[ZtInfo, SymmetricKey]> {
    const salt = randomBytes(32)
    const zi = new ZtInfo({rounds, salt, hashType})
    return [zi, await zi.deriveKey(password)]
  }
}

const ztLabel = decode64("GS2gEN77YDU=")

/**
 * ZeText (see zetext app) is a simple encrypted text container.
 */
export class ZeText {
  constructor(
    public readonly info: ZtInfo,
    public readonly key: SymmetricKey,
    public readonly plaintext: string) {
  }

  /**
   * Encrypt and pack ZeText object.
   * @param text text to encrypt
   * @param password password to encrypt with
   * @param rounds PBKDF rounds to use
   * @param hashType hash to use with PBKDF2 HMAC
   * @return encrypted and packed zetext object
   */
  static async pack(text: string,password: string,rounds = 100,
                    hashType = HashType.SHA3_384): Promise<Uint8Array> {
    const zi = new ZtInfo({hashType,rounds});
    const key = await zi.deriveKey(password);
    const bw = new Boss.Writer();
    bw.write(zi);
    bw.write(await key.etaEncrypt(textToBytes(text)));
    return concatenateBinary( ztLabel, bw.get() );
  }

  /**
   * Decrypt ZeText with a given password
   * @param password to decrypt with
   * @param packed binary content of zetext file (object)
   * @return promize to unpacked zetext
   * @throws InvalidPasswordError
   * @throws InvalidFormatError
   */
  static async unpack(packed: Uint8Array, password: string): Promise<ZeText> {
    // first is a fixed label
    const label = packed.slice(0, 8)
    if( !equalArrays(ztLabel, label) ) throw new InvalidFormatError("wrong file label");

    // now the password key derivation attributes
    const br = new Boss.Reader(packed.slice(8));
    const zi = new ZtInfo(br.read() as ZtInfo);
    const key = await zi.deriveKey(password);
    // and the
    return new ZeText(zi, key, bytesToText(await key.etaDecrypt(br.read() as Uint8Array)));
  }
}
