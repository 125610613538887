import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Droplets from "@/views/droplets.vue";
import EditNote from "@/views/EditNote.vue";
import NewNote from "@/views/NewNote.vue";
import ConsentRequest from "@/views/ConsentRequest.vue";
import Info from "@/views/Info.vue";
import ShowLegalDoc from "@/views/ShowLegalDoc.vue";
import Settings from "@/views/Settings.vue";
import MD from "@/views/MD.vue";
import ExportZtext from "@/views/ExportZtext.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/folders/:id?',
    name: 'Folders',
    component: Home
  },
  {
    path: '/droplets',
    name: 'Droplets',
    component: Droplets
  },
  {
    path: '/new',
    name: 'NewNote',
    component: NewNote
  },
  {
    path: '/notes/:id',
    name: 'EditNote',
    component: EditNote
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/info',
    name: 'Info',
    component: Info
  },
  {
    path: '/archive',
    name: 'Archive',
    component: ExportZtext
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/consent/:id?',
    name: 'Consent',
    component: ConsentRequest
  },
  {
    path: '/privacy_policy', redirect: '/show_doc/PPOL'
  },
  {
    path: '/show_doc/:id',
    name: 'Document viewer',
    component: ShowLegalDoc
  },
  {
    path: '/d/:id',
    name: 'Document',
    component: MD
  }

]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

export const RouterExtra = {
  blockBack: false
};

router.beforeEach((to, from, next) => {
  if (RouterExtra.blockBack) {
    console.log("back globally blocked!");
    next(false);
  }
  else {
    console.log("back globally unblocked");
    next()
  }
})

export default router
