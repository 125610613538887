

























import { Component, Vue } from "vue-property-decorator";
import MenuLayout from "../components/MenuLayout.vue";
import CardNode from "../components/CardNode.vue";
import { DocNode } from "../myonly.notes/DocNode";
import WaitOverlay from "../components/WaitOverlay.vue";
import { parseMarkdown } from "@/myonly.notes/mdparser";
import { ConsentDoc, NoConsentAction } from "@/docs/ConsentDoc";
import { errorToast } from "@/lib/toasts";
import { LegalDocs } from "@/docs/LegalDocs";
import { BPA_doc } from "@/docs/BPA";

@Component({
  components: { WaitOverlay, CardNode, MenuLayout }
})
export default class ConsentRequest extends Vue {
  node: DocNode | null = null;
  documentId?: string;
  document?: ConsentDoc;
  rejected = false;
  noConsent: NoConsentAction = "block";

  get docTag(): string {
    return `consent_${this.documentId}`;
  }

  accept() {
    this.document?.accept();
    this.$router.back();
  }

  cancel() {
    if( this.noConsent == "block")
      this.rejected = true
    else
      this.$router.back();
  }

  mounted() {
    this.documentId = this.$route.params.id ?? "BPA";
    const doc = LegalDocs[this.documentId];
    if (!doc) {
      errorToast(this.$parent, "can't find document" + this.documentId);
      this.$router.back();
    } else {
      if (doc.isAccepted)
        this.$router.back();
      else {
        if( this.$route.query.action == "return" ) this.noConsent = "return";
        this.document = doc;
        this.node = parseMarkdown(doc.text);
      }
    }
  }
}
