












import { Component, Vue } from "vue-property-decorator";
import MenuLayout from "../components/MenuLayout.vue";
import CardNode from "../components/CardNode.vue";
import { DocNode } from "../myonly.notes/DocNode";
import WaitOverlay from "../components/WaitOverlay.vue";
import { BPA_doc } from "../docs/BPA";
import { parseMarkdown } from "@/myonly.notes/mdparser";
import { PPOL_doc } from "@/docs/PPOL";
import { LegalDocs } from "@/docs/LegalDocs";
import { errorToast } from "@/lib/toasts";

@Component({
  components: { WaitOverlay, CardNode, MenuLayout }
})
export default class MD extends Vue{
  node: DocNode | null = null;
  errorString: string | null = null;

  mounted() {
    //
    const docId = this.$route.params.id;
    try {
      const source = require(`../assets/md/${docId}.md`).default;
      this.node = parseMarkdown(source);
    }
    catch(e) {
      this.errorString = (e as any).toString();
    }
  }
}
