export class MutexCallback {

  private requestRepeat = false;
  private performingNow = false;

  constructor(private callback: () => Promise<void>) {
  }

  private handle?: any;

  performLater(afterMillis: number) {
    if( afterMillis < 0 ) throw new Error("timeout should be positive");
    if( this.handle ) clearTimeout(this.handle);
    this.handle = setTimeout(()=>{
      this.handle = undefined;
      this.perform();
    }, afterMillis);
  }

  async perform(requestRepeat=true): Promise<void> {
    if (this.performingNow)
      this.requestRepeat = requestRepeat;
    else {
      this.performingNow = true;
      do {
        this.requestRepeat = false;
        try {
          await this.callback();
        } catch (e) {
          console.error("error in ExtendedMutexCallback", e);
        }
      } while (this.requestRepeat)
      this.performingNow = false;
    }
  }
}
