







import { Component, Vue } from "vue-property-decorator";
import MenuLayout from "../components/MenuLayout.vue";
import CardNode from "../components/CardNode.vue";
import { DocNode } from "../myonly.notes/DocNode";
import WaitOverlay from "../components/WaitOverlay.vue";
import { BPA_doc } from "../docs/BPA";
import { parseMarkdown } from "@/myonly.notes/mdparser";
import { PPOL_doc } from "@/docs/PPOL";
import { LegalDocs } from "@/docs/LegalDocs";
import { errorToast } from "@/lib/toasts";

@Component({
  components: { WaitOverlay, CardNode, MenuLayout }
})
export default class ShowLegalDoc extends Vue{
  node: DocNode | null = null;

  mounted() {
    const docId = this.$route.params.id;
    const doc = LegalDocs[docId];
    if( !doc ) {
      errorToast(this.$parent, "can't find document: "+docId);
      this.$router.back();
    }
    else
      this.node = parseMarkdown(doc.text);
  }
}
