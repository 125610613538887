export async function timeout(millis: number): Promise<void> {
  return new Promise(resolve => {
    setTimeout(resolve, millis);
  })
}

export function validateEmail(email: string): boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export class LocallyStoredString {
  private cachedValue?: string;

  constructor(public readonly name: string, defaultValue: string) {
    this.cachedValue = localStorage.getItem(name) ?? defaultValue;
  }

  get value(): string {
    return this.cachedValue!
  }

  set value(newValue: string) {
    this.cachedValue = newValue;
    localStorage.setItem(this.name, newValue);
  }
}

export class LocallyStoredOptString {
  private cachedValue: string | null = null;

  constructor(public readonly name: string, defaultValue: string | null = null) {
    this.cachedValue = localStorage.getItem(name) ?? defaultValue;
  }

  get value(): string | null {
    return this.cachedValue ?? null;
  }

  set value(newValue: string | null) {
    this.cachedValue = newValue;
    if (newValue)
      localStorage.setItem(this.name, newValue);
    else
      localStorage.removeItem(this.name);
  }
}

export function ensureNotEmpty(value: any | null | undefined, errorText = "parameter is empty"): void {
  if (value && value != "" && value != "0") return;
  const e = new Error(errorText);
  console.error(errorText,e)
  throw e;
}

export function isLocalDev(): boolean {
  const host = window?.location?.host
  if( host == null ) return false;
  return host.startsWith("localhost:") || host.startsWith("127.0.0.1");
}

export function ifLocalTest(testValue: string,prodValue=""): string {
    return isLocalDev() ? testValue : prodValue;
}

export function padz(value: number, size: number): string {
  let num = value.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export function allLatin(x: string): boolean {
  return x.match(/^[a-z][a-z0-9'" ]*$/i) != null;
}

export function equalSets<T>(as: Set<T>, bs: Set<T>): boolean {
  if (as.size !== bs.size) return false;
  for (const a of as) if (!bs.has(a)) return false;
  return true;
}
