







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AsyncLine extends Vue {
  @Prop({default: true}) loading!: boolean
  @Prop({default: "75%"}) width!: string
}
