





















import { Component, Vue } from "vue-property-decorator";
import { EmitterHandle } from "uparsecjs/dist/Emitter";
import { Notes } from "@/myonly.notes/Notes";
import SmallCenterCol from "@/components/SmallCenterCol.vue";
import SignIn from "@/components/SignIn.vue";
import Register from "@/components/Register.vue";
@Component({
  components: { Register, SignIn, SmallCenterCol }
})
export default class Auth extends Vue {
  loggedIn = Notes.loggedInEvent.state;
  listener?: EmitterHandle;

  mounted() {
    this.listener = Notes.loggedInEvent.addListenerAndNotify((x) => this.loggedIn = x)
  }

  beforeDestroy() {
    this.listener?.unsubscribe();
    this.listener = undefined;
  }
}
