import { BPA_doc } from "@/docs/BPA";
import { PPOL_doc } from "@/docs/PPOL";
import { ConsentDoc, NoConsentAction } from "@/docs/ConsentDoc";
import { Vue } from "vue-property-decorator";
import { errorToast } from "@/lib/toasts";
import { EENCR_doc } from "@/docs/EENCR";
import { RMWARN_doc } from "@/docs/RMWARN";
import { PWDD_doc } from "@/docs/PWDD";

export const LegalDocs: Record<string, ConsentDoc> = {
  BPA: BPA_doc,
  PPOL: PPOL_doc,
  EENCR: EENCR_doc,
  RMWARN: RMWARN_doc,
  PWDD: PWDD_doc
};

Object.freeze(LegalDocs);

export function requireConsent(component: Vue,docId: string,onReject: NoConsentAction = "block"): boolean | null {
  const doc = LegalDocs[docId];
  if( !doc ) {
    errorToast(component, "missing document: "+docId);
    return null;
  }
  else {
    if( !doc.isAccepted ) {
      component.$router.push(`/consent/${docId}?action=${onReject}`);
      return null;
    }
    else return true;
  }
}
