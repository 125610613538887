





































import { Component, Vue } from "vue-property-decorator";
import LoggedIn from "@/components/LoggedIn.vue";
import MenuLayout from "@/components/MenuLayout.vue";
import CryptoTitle from "@/components/CryptoTitle.vue";
import { timeout } from "uparsecjs";
import { errorToast, infoToast } from "@/lib/toasts";
import { MyoNotes } from "@/myonly.notes/MyoNotes";
import { Notes } from "@/myonly.notes/Notes";
import { MyoNote } from "@/myonly.notes/MyoNote";
import { ZeText } from "@/lib/ztext";
import { unicryptoReady } from "unicrypto";
import dayjs from "dayjs";

@Component({
  components: {
    LoggedIn, MenuLayout, CryptoTitle
  }
})
export default class ExportZtext extends Vue {

  password1 = ""
  password2 = ""
  fileName = "zenotes_archive"
  waitMessage: string | null = null
  kdfRounds = 500000


  password1State(): boolean | null {
    return this.password1.length > 6;
  }

  password2State(): boolean | null {
    if( this.password1.length == 0 ) return null;
    return this.password2 === this.password1;
  }

  fileNameState(): boolean | null {
    return this.fileName.trim() != "";
  }

  canArchive(): boolean {
    return this.fileNameState() == true && this.password1State() == true && this.password2State() == true;
  }

  async createArchive() {
    try {
      await unicryptoReady;
      const password = ""+this.password1;
      const rounds = +this.kdfRounds;
      this.waitMessage = "Creating message archive...";
      await Notes.db?.loadNotesFromDatabase()
      const nn: MyoNote[] = Notes.db?.allNotes?.all!;
      this.waitMessage = `Exporting ${nn.length} notes...`

      let result =
`# Archive of [zenotes](https://zenotes.cloud)

[//]: # (zenotes:archive:zenotes.markdown.1)
`
      for( const note of nn) {
        result += `\n\n-------------------------------------------------------------\n[//]: # (note: ${note.title})\n\n`
        if( note.title ) result += `# ${note.title}\n\n`
        result += note.text + "\n"
      }

      console.log("selected rounds:", +rounds);
      const packed = await ZeText.pack(result, password, rounds);
      this.saveByteArray(`${this.fileName}.ztext`, packed);

      infoToast(this.$parent, "Archive has been created");
      this.$router.back();
    }
    catch(e) {
      errorToast(this, "Archive creation failed: "+e);
    }
    finally {
      this.waitMessage = null;
    }
  }

  saveByteArray(fileName: string, bytes: Uint8Array) {
    var blob = new Blob([bytes], {type: "application/octet-stream"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = fileName;
    link.download = fileName;
    link.click();
  };

  mounted() {
    const time = dayjs().format("YYMMDDHHmmss")
    this.fileName = `zenotes_${time}`
  }

}
