














import { Component, Prop, Vue } from "vue-property-decorator";
import { MyoNote } from "../myonly.notes/MyoNote";
import CardNode from "@/components/CardNode.vue";
import { Passwords } from "uparsecjs";

@Component({
  components: { CardNode }
})
export default class NoteCard extends Vue {
  @Prop() note!: MyoNote;

  expanded = false;
  noteId = Passwords.randomId(32);

  toggleNote() {
    console.log(this.$refs);
    const et = (this.$refs[this.noteId] as HTMLElement)
    const rc = et.getBoundingClientRect();
    console.log(et.offsetWidth, et.offsetHeight);
    console.log(et.clientWidth, et.clientHeight);
    console.log(et.scrollWidth, et.scrollHeight);
    const w = et.scrollWidth;
    const h = et.scrollHeight;
    const tw = window.innerWidth;
    // for( const x of et.classList ) {
    //   console.log(x)
    //   et.classList.remove(x)
    // }
    console.log(rc);
    et.style.position = "fixed";
    et.style.left = `${rc.left}px`;
    et.style.top = `${rc.top}px`;
    et.style.width = `${w}px`;
    // et.style.right = `${tw - w}px`;
    et.style.height = `${h}px !important`;
    et.style.background = "lightgreen";
    et.style.zIndex = "1000";
    this.$nextTick(() => et.classList.toggle("zoomed") );
  }

  get showFooter(): boolean {
    return this.note.isDirty;
  }
}
