/* eslint-disable @typescript-eslint/no-use-before-define */

import { APIResult } from "@/digitalocean/APIResult";
import { DropletInfo } from "@/digitalocean/droplets";

if (!window.fetch)
  window.fetch = require('node-fetch');

export class DOApi {

  static Exception = class extends Error {
    constructor(readonly errorId: string, readonly errorMessage?: string) {
      super(`(${errorId}): ${errorMessage ? errorMessage : "error"}`);
    }
  };

  logger?: (...args: string[]) => void;

  #headers: { Authorization: string; "Content-Type": string };

  constructor(apitoken: string) {
    const token = apitoken;
    this.#headers = { Authorization: `Bearer ${token}`, "Content-Type": "application/json" };
  }

  async request<T>(method: string, path: string, params: Record<string, any> = {}): Promise<T & APIResult> {
    const url = path.startsWith("https://") ? path : "https://api.digitalocean.com/v2/" + path;
    if (method == "GET") {
      let query = "?";
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          query += `${key}=${encodeURIComponent("" + params[key])}`;
        }
      }
      const fullUrl = query.length > 1 ? url + query : url;
      this.logger?.call(`>> ${method} ${fullUrl}`);
      return checkResult(
        fetch(fullUrl, {
          method,
          headers: this.#headers,
        })
      );
    } else
      return checkResult(
        fetch(url, {
          method,
          headers: this.#headers,
          body: JSON.stringify(params)
        })
      );
  }

  get<T>(path: string, params = {}): Promise<T & APIResult> {
    return this.request("GET", path, params);
  }

  post<T>(path: string, params = {}): Promise<T & APIResult> {
    return this.request("POST", path, params);
  }

  private allDropletsPromise?: Promise<DropletInfo[]>;

  allDroplets(reload = false): Promise<DropletInfo[]> {
    if (reload) this.allDropletsPromise = undefined;
    if (!this.allDropletsPromise) {
      this.allDropletsPromise = (async () => {
        let next: string | undefined = "droplets?page=1&per_page=100";
        const droplets = new Array<DropletInfo>();
        while (next) {
          this.logger?.("::: loading page " + next);
          const result: APIResult & { droplets: DropletInfo[] } = await this.get(next);
          droplets.push(...result.droplets);
          next = result.links.pages.next;
        }
        return droplets;
      })();
    }
    return this.allDropletsPromise;
  }

  async turnOn(droplet: number | DropletInfo): Promise<void> {
    let id = typeof (droplet) == 'number' ? droplet : droplet.id;
    let result = await this.post(`droplets/${id}/actions`, { type: 'power_on' });
  }

}

async function checkResult<T>(response: Promise<Response>): Promise<T> {
  const result = await (await response).json() as T & APIResult;
  if (result.id)
    throw new DOApi.Exception(result.id, result.message);
  return result;
}


