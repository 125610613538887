import { ConsentDoc } from "@/docs/ConsentDoc";
import { LegalIds } from "@/docs/LegalIds";

const product = LegalIds.product;

export const EENCR_doc = new ConsentDoc({
  version: 1,
  name: "End-to-end encryption in ZeNotes",
  id: "EENCR",
  text: `

# Data Encryption in ${product} 

## End to end encryption

The term of end-to-end encryption (E2EE) initially mean the way of exchanging encrypted data between parties by mean of encrypted data packets which are encrypted and decrypted on their terminal hardware, e.g. on the computer, or smartphone, or special communication device. Not on the server, like we do with emails, most messengers, popular clouds, etc. And, what is most important, the encryption keys are only possessed by the communicating parties.

When it comes to the cloud storages, this term is applicable also. Suppose you create note on one device (say, your smartphone), save it in encrypted form to the cloud, and then download and use it on your notebook. If the keys to decrypt your notes are only available on your devices, after you enter your password, it will also be End-To-End encryption. 

The question of the key possession is most important one. For example, most popular cloud storages form end users, like on most popular smartphones, also encrypt data on the device and save it encrypted. Meanwhile, these _cloud services do poses copies of your keys_. Of course it is done to provide best service for customers, for example, to restore access to your sensitive data to whoever will hack your email account, or to automatically scan your notes for copyright infringement, child abuse instructions, new business ideas or whatever else that provider might find of interest. We do appreciate an effort to limit any privacy for private users, and it is where ZeNotes comes to help. 

We do not store your passwords or keys. We have no idea what is stored in the cloud. Even in unlikely case some malefactor will get full access to all myonly.cloud servers, it will not disclose a letter of your notes. There still are drawbacks and benefits of it.

### E2EE drawbacks

#### Password recovery is impossible.

This is direct consequence of not having access to your private data. If you loose your password, you loose all of your data. the good part of it is that your notes are safe against popular ID stealing trick. It means, even if somebody will steal your computer or mobile phone, your email or cellular number, it won't compromise your data. Your data are save __as long as your password is complex and undisclosed__.

#### There could be no native application for iPhone

Any non-breakable encryption that leaves no way to access stored data on the phone without user consent is strictly prohibited to be installed from US software stores, due to "export limitations". That's why we have written this outstanding PWA application, that is not served from USA, working in your mobile or desktop browser, all written in javascript (typescript actually) anc C++ (wasm modules), it works comparable well, almost like a native application, even without internet connection.

#### Crypto processing is slow.

As all strong and slow cryptography is performed in your browser, which was not primarily designed to run heavy computation, it is not blazing fats. Well, robust things are known to be more reliable than fast. It's the price for your privacy. To keep your privacy at max level, we do not rely on crypto libraries provided by browser/OS (these tend to have exploits), we provide complete cryptographic solution in wasm. It is still not as fast as a native library/app.  

#### You have to enter your password often

We keep all data in the browser storages encrypted with separate keys, also derived from your password. It is inevitable that otherwise any malefactor can steal your data from your local disk. An there is no place where to keep your password safe in browser, so for the maximum security we ask you to enter password starting each new session, and wait until the keys will be derived from it. 

### E2EE Benefits

#### Your data are safe against most virus attacks

The data stored in your browser (and this on local disks) could not be decrypted without your password, so no virus that steal local disk data would be able to get it. Viruses that copy data from the screen or keyloggers are still a danger.

#### Loosing your devices do not compromise your data

Same as above. You can get it back in the nearest internet cafe or as soon as you'll get new smartphone or a notebook. And your data will note be compromised, whats the best.

#### Safety against device scanning in some countries.

There are few countries that have a practice of stealing all the data from your devices when you cross the border. Your notes won't be decrypted by it, though there is always a risk that border guards will install a couple of viruses.

## Encryption keys

ZeNotes uses an sophisticated system of different keys to keep all parts of your data safe. All starts from the password you have to enter manually. Then, the special slow cryptographic key derivation algorithm (PBKDF) that provides two independent keys. From these keys are derived local storage keys to encrypt data in browser. These keys are unique per browser installation and are independent from other keys. One of first pair is then used to decrypt a login key, strong a nd bog RSA key that is used to grant you access to your encrypted data - not to decrypt it. And second from pair is used to decrypt cloud encryption key, stored also in encrypted form in the cloud, and accessible only after successful decryption of RSA login key. All this complex system allow the service to know nothing about your keys and passwords.

This is why our PWA application is that big. All this processing is being done in the web page, Single Page Application, on your mobile or desktop browser. 

## Offline use

As ZeNotes keep encrypted copy of your data in the browser (both indexedDB and localStorage are used, but with different encryption keys), it is possible to decrypt it and use offline solely by providing your password. It still takes time to generate keys out of your password, but then you can work with no internet connection fairly well. All changes will be sent to the cloud as soon as the connection will be restored.   


`
});
