import { ConsentDoc } from "@/docs/ConsentDoc";
import { LegalIds } from "@/docs/LegalIds";

const product = LegalIds.product;

export const RMWARN_doc = new ConsentDoc({
  version: 1,
  name: "Remember Me Mode Warning",
  id: "RMWARN",
  text: `

# Remember Me mode vulnerability

__Remember me mode is generally insecure against some attacks!__

## Behind remember me

When you select "remeber me" checkbox, ZeNotes will encrypt and store your password keys also in the local storage, thus
encrypted password will hit the disk. When you repoen ZeNotes, software detects and decrypt your keys so you can 
continue using your notes without re-entering password. Though convenien, it is much less secure introducing following 
threats:

### Copying your disk contents

Though ZeNotes apply reasonable effort to keep it hard to recover, it is still possible for a malefactor having access 
to the device disk (or copied data) to decrypt your keys and then all your data from the cloud. Taking into
account constant virus attack probability, or chance to be stolen of your devices, this is a real threat.

### Forgetting your password

Usually, you have to enter your password when starting ZeNotes application. This way you keep it fresh in your memory 
and reduce the risk of forgetting it. As forgotten password can not be recovered, it's a risk to loose all you data.  

----------------------------------

WE DO NOT RECOMMEND USING REMEMBER ME FEATURE AND RECOMMEND YOU TO REFRAIN OF USING IT. IF YOU USE IT, YOU DO
IT ON YOUR RISK AND ACCEPT YOUR SOLE RESPONSIBILITY FOR POSSIBLE DATA DISCLOSURE OR LOSS.
`
});
