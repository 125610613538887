










































import { Component, Vue } from 'vue-property-decorator';
import LoggedIn from "@/components/LoggedIn.vue";
import MenuLayout from "@/components/MenuLayout.vue";
import ShowFolder from "@/components/ShowFolder.vue";
import { MyoNote } from "@/myonly.notes/MyoNote";
import { Folder } from "@/model/Folder";
import { FolderDefinition } from "@/myonly.notes/FolderDefinition";
import { Notes } from "@/myonly.notes/Notes";
import { SearchFolder } from "@/myonly.notes/SearchFolder";
import { timeout } from "uparsecjs";
import { EmitterHandle } from "uparsecjs/dist/Emitter";
import { LocallyStoredString } from "@/lib/tools";
import { SessionStoredValue } from "@/lib/storage_tools";

@Component({
  components: {
    ShowFolder,
    LoggedIn, MenuLayout
  },
})
export default class Home extends Vue {
  // cloud = new MyoCloud(new MemorySessionStorage(), {serviceAddress:"http://localhost:8094/api/v1"});

  folderDef: FolderDefinition | null = null;
  search = ""
  folder: Folder<MyoNote> | null = null;

  private doShowTrash: boolean | null = null;
  private searchFolder?: SearchFolder;
  private handler?: EmitterHandle;

  get title(): string {
    switch (this.folderName) {
      case 'home':
        return "Notes";
      case '$$trash$$':
        return "Recycle bin";
      default:
        return this.folderName;
    }
  }

  selectClasses(expectedName: string): string[] {
    return this.folderName == expectedName ? ['current'] : [];
  }

  searchChanged() {
    if (this.searchFolder) {
      this.searchFolder.close();
    }
    if (this.search.trim() != "") {
      this.searchFolder = new SearchFolder(this.search);
    } else
      this.searchFolder = undefined;
    this.showProperFolder();
  }

  clearSearch() {
    this.search = "";
    this.searchFolder?.close();
    this.searchFolder = undefined;
    this.showProperFolder();
  }

  async clearTrashcan() {
    console.log("clearing the trashcan");
    const nn = new Array<MyoNote>();
    if (Notes.db) {
      for await (const note of Notes.db.trashed()) {
        note.setDeleted();
        nn.push(note);
      }
      for (const note of nn)
        await Notes.db.saveNote(note);
    }
  }

  folderName = "home";

  async showProperFolder() {
    this.folderName = this.$route.params.id ?? "home";
    await Notes.dbp;
    if (this.searchFolder)
      this.folder = this.searchFolder;
    else {
      if (this.folderName == "$$trash$$")
        this.folder = Notes.db?.deletedNotes ?? null;
      else {
        this.folder = Notes.db?.activeNotes ?? null;
      }
    }
  }


  async mounted() {
    this.searchFolder?.close();
    this.searchFolder = undefined;
    this.handler = Notes.loggedInEvent.addListenerAndNotify(x => {
      if (x) this.showProperFolder()
    });
  }

  beforeDestroy() {
    this.handler?.unsubscribe();
    this.handler = undefined;
  }
}
