import { Passwords } from "uparsecjs";
import { equalSets } from "@/lib/tools";
import { MapSerializer } from "myonlycloud/dist/MapSerializer";
import { CloudElement, MyoCloud, MyoElement, SharedBox } from "myonlycloud";
import { DocNode, DocNodeBlock, DocNodeText } from "@/myonly.notes/DocNode";
import { parseMarkdown } from "@/myonly.notes/mdparser";
import { randomBytes } from "unicrypto";
import { FolderDefinition } from "@/myonly.notes/FolderDefinition";
import { FolderItem } from "@/model/Folder";
import { MyoNotes } from "@/myonly.notes/MyoNotes";

export type MyoNoteFormat = "md"

export type ResolutionMerge = (our: MyoNote, their: MyoNote) => Promise<MyoNote>;

export type CloudSyncStatus = "draft" | "dirty" | "synchronized";

export type ResolutionStrategy = "error" | "overwrite" | ResolutionMerge;

export class MyoNote implements FolderItem {

  cloudId?: number;
  uniqueTag!: string;
  title?: string;
  text!: string;
  format!: MyoNoteFormat;
  createdAt!: Date;
  updatedAt!: Date;
  couldBeInCloud?: boolean;
  isPinned?: boolean;
  serial?: number;
  revision?: number;
  draftOf?: string;
  trashedAt?: Date;
  isDirty: boolean = false;
  markedForDeletion: boolean = false;
  tags = new Set<string>();
  status: CloudSyncStatus = "draft";
  folders: FolderDefinition[] = [];

  constructor(mn: Partial<MyoNote>) {
    Object.assign(this, mn);
    if (this.text === undefined) throw new Error("note must have text");
    if (!this.createdAt) this.createdAt = new Date();
    if (!this.updatedAt) this.updatedAt = this.createdAt;
    if (!this.uniqueTag) this.uniqueTag = Passwords.randomId(42);
  }

  get uniqueId(): string {
    return this.uniqueTag;
  }

  equalsTo<T extends FolderItem>(other: T): boolean {
    return this.uniqueTag == other.uniqueId && other instanceof MyoNote && this.sameData(other);
  }

  setDeleted() {
    if (!this.markedForDeletion) {
      this.isDirty = true;
      this.markedForDeletion = true;
      this.text = "";
      this.title = undefined;
    }
  }

  set trashed(value: boolean) {
    if (value) {
      if (!this.trashedAt) {
        this.trashedAt = new Date();
        this.isDirty = true;
      }
    } else {
      if (this.trashedAt !== undefined) {
        this.trashedAt = undefined;
        this.isDirty = true;
      }
    }
  }

  get trashed(): boolean {
    return this.trashedAt !== undefined;
  }

  get isDeleted(): boolean {
    return this.markedForDeletion;
  }

  get docNode(): DocNode {
    const result = parseMarkdown(this.text ?? "", true);
    if (this.title)
      result.prepend(new DocNodeBlock().append(new DocNodeText({ text: this.title }).addClass("doc-node-title")));
    return result;
  }

  /**
   * Compare note data, not revisions, dates, statuses, etc. E.g. same title, same text, same tags.
   */
  sameData(other: MyoNote): boolean {
    return this.text === other.text && this.title === other.title && equalSets(this.tags, other.tags) &&
      this.isDirty == other.isDirty && this.isPinned == other.isPinned && this.trashed == other.trashed;
  }

  async packToCloud(cloud: MyoCloud): Promise<CloudElement> {
    // TODO: pack note
    const tag1 = await cloud.scramble("MyoNote");
    // const tag2 = this.draftOf ? await cloud.scramble("draft: " + this.draftOf) : undefined;
    const tag2 = await scrambleDraftTag(cloud, this.draftOf);
    const tag3 = this.markedForDeletion ? await cloud.scramble("toDelete") : undefined;
    let head: Uint8Array;
    if (this.markedForDeletion)
      head = randomBytes(7)
    else {
      const sbox = await SharedBox.createWithPacked(await MapSerializer.toBoss(this));
      await sbox.addKeys(await cloud.storageKey);
      head = await sbox.pack();
    }
    return {
      uniqueTag: this.uniqueTag,
      tag1, tag2, tag3,
      head
    };
  }

  static unpackFrom(packed: MyoElement): Promise<MyoNote> {
    return this.unpackFromCloud(packed.cloud, packed);
  }

  static async unpackFromCloud(cloud: MyoCloud, packed: CloudElement): Promise<MyoNote> {
    if (packed.tag1 !== await cloud.scramble("MyoNote"))
      throw new Error("this is not a MyoNote: tag1 does not match");
    if (packed.tag3 == await cloud.scramble("toDelete")) {
      // do not unpack deleted note
      return new MyoNote({
        markedForDeletion: true,
        text: "",
        serial: packed.serial,
        cloudId: packed.id,
        uniqueTag: packed.uniqueTag
      })
    }
    if (!packed.head)
      throw new Error("this is not a MyoNote: head data missing");
    const sbox = await SharedBox.unpack(packed.head);
    await sbox.unlockWithRing(await cloud.mainRing);
    const unpacked: MyoNote = await MapSerializer.fromBoss(await sbox.payloadPromise);
    if (unpacked.uniqueTag != packed.uniqueTag)
      throw new Error("this is not a MyoNote: uniqueTag does not match");
    // this is important: whatever state it is, note loaded from the cloud
    // can not be dirty - otherwise it will immediately been resubmitted:
    unpacked.isDirty = false;
    // and actualize could-set data:
    unpacked.serial = packed.serial;
    unpacked.cloudId = packed.id;
    unpacked.revision = packed.revision;
    // now its ready
    return new MyoNote(unpacked);
  }
}

MapSerializer.registerCaseObject(MyoNote);

export interface MyoNoteEvent {
  type: "updated" | "deleted";
  note: MyoNote;
}


export async function scrambleDraftTag(cloud: MyoCloud,draftOf: string | undefined): Promise<string | undefined> {
  return draftOf ? await cloud.scramble("draft: " + draftOf) : undefined;
}
