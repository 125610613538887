




import { Component, Vue } from "vue-property-decorator";
import { Notes } from "@/myonly.notes/Notes";
import { MyoNote } from "@/myonly.notes/MyoNote";
import { errorToast } from "@/lib/toasts";
import NoteEditor from "@/components/NoteEditor.vue";

MyoNote;

@Component({
  components: { NoteEditor }
})
export default class EditNote extends Vue {

  note: MyoNote | null = null;

  async mounted() {
    if (this.$route.params.id) {
      this.note = await Notes.db?.getNote(this.$route.params.id) ?? null;
      if (!this.note) {
        await this.$router.back();
        errorToast(this, "note not found");
        return;
      }
    }
    else {
      errorToast(this.$parent, "note not provided");
      this.$router.back();
    }
  }
}
