




























import { Component, Vue } from "vue-property-decorator";
import { EmitterHandle } from "uparsecjs/dist/Emitter";
import { Notes } from "@/myonly.notes/Notes";
import { ifLocalTest, timeout } from "@/lib/tools";
import { errorToast, infoToast, warningToast } from "@/lib/toasts";

@Component({})
export default class LoggedIn extends Vue {
  loggedIn = Notes.loggedInEvent.state;
  listener?: EmitterHandle;
  wait = -1;
  waitCryptoSpeed = 0.0;
  waitTitle = "Initializing";
  waitMessage: string | null = null;
  mode: "check" | "password" | "auth" | "ready" | "login_required" = "check";
  passwordCheckState: boolean | null = null;
  password = ifLocalTest("12345qwert!");
  rememberMe = false;

  goAuth() {
    this.wait = -1;
    this.mode = "auth";
    Notes.connect();
  }

  async doRestoreSession() {
    // bootup algorithm
    console.log("Checking restore possibility: " + Notes.hasStoredSession);
    if (Notes.hasStoredSession) {
      // can we remember?
      if (await Notes.tryRestoreRememberedSession()) {
        this.mode = "ready";
        this.wait = -1;
        return;
      }
      console.log("remember me failed");
      // Stored session mmight not exist after calling tryRestoreRememberedSession(), it could clear it
      // if something went wrong.
      // this.wait = 0;
      // this.mode = "check";
      // enter password
      console.log("stored session found");
      this.wait = -1;
      this.mode = "password";
      return;
    }
    this.goAuth();
  }

  mounted() {
    this.listener = Notes.loggedInEvent.addListenerAndNotify((x) => this.loggedIn = x)
    this.doRestoreSession();
  }

  dropSession() {
    Notes.deleteLocalData();
    this.mode = "login_required";
    Notes.connect()
  }

  async passwordEntered() {
    if (this.password == "") return;
    this.passwordCheckState = null;
    try {
      this.wait = 0;
      this.waitTitle = "Decrypting...";
      this.waitCryptoSpeed = 0.3;
      if (await Notes.tryRestoreSettings(this.password, this.rememberMe)) {
        this.mode = "ready";
      } else {
        this.mode = "password";
        this.passwordCheckState = false;
      }
    } catch (e) {
      this.wait = -1;
      errorToast(this, "" + e, "Restore failed");
    } finally {
      this.wait = -1;
    }
  }

  beforeDestroy() {
    this.listener?.unsubscribe();
    this.listener = undefined;
  }
}
