








import { Component, Prop, Vue } from "vue-property-decorator";
import WaitScreen from "./WaitScreen.vue";

@Component({
  components: { WaitScreen }
})
export default class WaitOverlay extends Vue{
  @Prop() show!: boolean;
  @Prop({default: "please, wait..."}) message!: string;
  @Prop({default: 0}) progress!: number;
  @Prop({default: null}) details!: string | null;
}
