import { Passwords } from "uparsecjs";

class DocNodeBase {
  type = "unknown";

  readonly guid = Passwords.randomId(17);
  readonly #_classes: string[] = [];
  readonly #_children: DocNode[] = [];

  /**
   * Get a copy of current class list. It could be modified and it won't change node class list.
   */
  get classes(): string[] {
    return [...this.#_classes];
  }

  get children(): DocNode[] { return [...this.#_children]; }

  append(...children: DocNode[]): DocNode {
    this.#_children.push(...children);
    return this;
  }

  prepend(...children: DocNode[]): DocNode {
    this.#_children.unshift(...children);
    return this;
  }

  addClass(...classNames: string[]): DocNode {
    for( const className of classNames) {
      if (this.#_classes.indexOf(className) < 0)
        this.#_classes.push(className);
    }
    return this;
  }

  removeClass(className: string): boolean {
    const i = this.#_classes.indexOf(className);
    if( i >= 0  ) {
      this.#_classes.splice(i, 1);
      return true;
    }
    return false;
  }
}

export class DocNodeBlock extends DocNodeBase {
  type = "block";

  constructor(props: Partial<DocNodeBlock> = {}) {
    super();
    Object.assign(this, { ...props });
    this.addClass("doc-node-block");
  }
}

export class DocNodeImage extends DocNodeBase {
  type = "image";
  src!: string;
  title?: string;

  constructor(props: Partial<DocNodeImage> = {}) {
    super();
    Object.assign(this, { ...props });
    if( this.src === undefined) throw new Error("image node must have src set");
    this.addClass("doc-node-image");
  }
}

export class DocNodeHR extends DocNodeBlock {
  type = "HR";

  constructor() {
    super({});
  }
}


export class DocNodeInline extends DocNodeBase {
  type = "inline";

  constructor(props: Partial<DocNodeBlock> = {}) {
    super();
    Object.assign(this, { ...props });
    this.addClass("doc-node-inline");
  }
}

export class DocNodeText extends DocNodeInline {
  type = "text";
  text?: string;

  constructor(props: Partial<DocNode>) {
    super();
    Object.assign(this, { ...props });
    this.addClass("doc-node-inline")
  }
}

export class DocNodeLink extends DocNodeText {
  type = "link";
  href!: string;

  constructor(props: Partial<DocNodeLink>) {
    super(props);
    Object.assign(this, { ...props });
    if (!this.href) throw new Error("DocNodeLink requires href field");
    if (!this.text) this.text = this.href;
    this.addClass("doc-node-link");
  }
}

export class DocNodePassword extends DocNodeText {
  type = "password";

  constructor(props: Partial<DocNodeLink>) {
    super(props);
    if( this.text == "" ) throw new Error("Password node needs non-empty text")
    this.addClass("doc-node-password");
  }
}

export class DocNodeCopyText extends DocNodeText {
  type = "copyText";

  constructor(props: Partial<DocNodeLink>) {
    super(props);
    if( this.text == "" ) throw new Error("CopyText node needs non-empty text")
    this.addClass("doc-node-copy-text");
  }
}

export type DocNode = DocNodeText | DocNodeLink | DocNodeBlock | DocNodeInline | DocNodeHR | DocNodeImage;

