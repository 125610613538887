import { decode64, encode64, SymmetricKey } from "unicrypto";
import { concatenateBinary, Passwords, sha256, sha3_384 } from "uparsecjs";
import { MapSerializer } from "myonlycloud/dist/MapSerializer";
import dayjs from "dayjs";

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

let _localSalt: Uint8Array | undefined;

/**
 * The local salt is `localStorage` based random salt that is generated on first call and then kept
 * in storage. It could be used to variate keys or identify instance. Sal is 384 bits long, this size
 * is cryptographically food in several situations, intentially made longer than 32 bytes ant not a power of 2.
 *
 * @return just generated or restored salt array.
 */
export async function localSalt(): Promise<Uint8Array> {
  if (_localSalt) return Promise.resolve(_localSalt);
  const key = "e7843lj;qwds907u";
  let salt = window.localStorage.getItem(key);
  if (!salt) {
    salt = Passwords.randomId(49);
    window.localStorage.setItem(key, salt);
  }
  _localSalt = await sha3_384(salt);
  return _localSalt;
}

interface RememberMeData<T> {
  payload: T,
  createdAt: Date,
  expiresAt: Date,
  expirationMinutes: number
}

/**
 * All-included remember me support based on window.local strage. Uses smart key, that uses special
 * salt unique in every browser instance (localStorage context). to make it more secure,
 * provide hard to consider binary salt in the contructor.
 */
export class RememberMeStorage {

  #innerKey: Promise<SymmetricKey> = (async () => {
    const src = concatenateBinary(await localSalt(), this.externalSalt);
    return new SymmetricKey({ keyBytes: await sha256(src) });
  })();


  /**
   * Construct remember me instance that will use a specified field name in local storage.
   * We recommend to use confusing names.
   * @param localField where to store encrypted data
   * @param externalSalt some binary salt needed to encrypt/decrypt data. this is not a key, but a salt,
   *                     binary sequence used to generate random keys.
   */
  constructor(private localField: string,private externalSalt: Uint8Array,private defaultExpirationMinutes=7) {
  }

  /**
   * Removes remember me data completely, and stops refresh timer if any.
   */
  clear() {
    window.localStorage.removeItem(this.localField);
    this.#lastPayload = undefined;
    if( this.#thandle ) clearInterval(this.#thandle);
  }

  #lastPayload?: RememberMeData<any>;
  #thandle: any;

  /**
   * Remember data, and start refreshing timer if requested. Autorefresh timer will pperiodically extend exporation
   * so it won't expire while the system is on. Otherwise call [[refresh]] manually as need.
   * @param data to remember
   * @param expirationMinutes best betore use time
   * @param autoRefresh enable auto-refresh expiration
   */
  async remember<T>(data: T,autoRefresh=true): Promise<void> {
    this.#lastPayload = {
      expirationMinutes: this.defaultExpirationMinutes,
      payload: data,
      createdAt: new Date(),
      expiresAt: dayjs().add(this.defaultExpirationMinutes, "minutes").toDate()
    };
    await this.save();
    this.#thandle = setInterval(() => this.refresh(), 10000);
  }

  private async save(): Promise<boolean> {
    if (this.#lastPayload) {
      this.#lastPayload.expiresAt = dayjs().add(this.#lastPayload.expirationMinutes, "minutes").toDate()
      window.localStorage.setItem(this.localField, encode64(
        await (await this.#innerKey).etaEncrypt(await MapSerializer.toBoss(this.#lastPayload))
      ));
      return true;
    }
    return false;
  }

  /**
   * Refreshing is extending expiration of saved data using duration provided with [[remember]] call.
   * It adds expirationMinutes to current time and saves data. Note that it will not expose or compromise
   * keys as it always generate new random IV on save.

   * It could be called automatically, if `autoRefresh` was called, or manually.
   */
  async refresh() {
    if( await this.save() )
      console.log("revived remember me");
    else {
      console.log("nothig to refresh, clearing timer");
      clearInterval(this.#thandle);
    }
  }

  /**
   * Try to recall remember me data. If it exists, and not expired, return it and start refreshing cycle.
   * If exists but outdated, clears it and returns undefined. If does not exists, just returns undefined.
   */
  async recall<T>(autoRefresh = true): Promise<T|undefined> {
    const packed = window.localStorage.getItem(this.localField);
    if (!packed) return undefined;
    try {
      const rmd: RememberMeData<T> = await MapSerializer.anyFromBoss(
        await (await this.#innerKey).etaDecrypt(decode64(packed))
      );
      console.log("decrypted remember me, valid until " + rmd.expiresAt);
      if (dayjs(rmd.expiresAt).isAfter(dayjs())) {
        this.#lastPayload = rmd;
        if( autoRefresh ) {
          this.#thandle = setInterval(()=>this.refresh(),10000);
          await this.refresh();
        }
        return rmd.payload;
      }
      console.log("remeber me data expired, clearing it");
    } catch (e) {
      console.warn("can't process remember me data " + e);
    }
    this.clear();
    return undefined;
  }
}
