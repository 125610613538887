import { utf8ToBytes } from "uparsecjs";

export function textState(text: string,minSize: number,additionalCheck?: (text: string) => boolean): boolean | null {
  if(text == "") return null;
  if(text.length < minSize) return false;
  if( additionalCheck ) return additionalCheck(text);
  return true;
}

export function c467(): Uint8Array {
  return utf8ToBytes("kdMGxA90O6MViJmutz3iyE6UfkoYXvPXBz3iTwnQJdqzOBT29");
}
