























import { Component, Prop, Vue } from "vue-property-decorator";
import { DocNode } from "../myonly.notes/DocNode";
import { errorToast, infoToast } from "@/lib/toasts";

@Component
export default class CardNode extends Vue {
  @Prop() node!: DocNode

  getImage(source: string): string {
    // return `./assets/md/${source}`;
    return require(`@/assets/md/as_app_mac.png`);
  }

  getClasses(source: string): string[] {
    const result = [];
    const styles = source.split('#')[1] ?? '';
    if (styles !== '') {
      for (const x of styles.split(';')) {
        switch (x) {
          case 'left':
            result.push("float-left", "mr-2")
            break;
          case 'right':
            result.push("float-right", "ml-2")
            break;
          case 'center':
            result.push("d-block", "text-center")
            break;
          case 'small':
            result.push("small-image");
            break;
        }
      }
    }
    return result;
  }

  async copyText(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      infoToast(this, "Content copied to clipboard", "Copied")
    } catch (e) {
      errorToast(this, "clipboard error, password was not copied");
    }
  }
}
