import { ConsentDoc } from "@/docs/ConsentDoc";
import { LegalIds } from "@/docs/LegalIds";

const owner = LegalIds.owner;
const product = LegalIds.product;

export const PWDD_doc = new ConsentDoc({
  version: 1,
  name: "Password Loss Disclaimer",
  id: "PWDD",
  text: `
# Password Loss Or Disclosure Disclaimer

## DISCLAIMER

THE ENCRYPTION ALGORITHMS AND METHODS IMPLEMENTED IN ${product} DOES NOT INCLUDE ANY POSSIBILITY TO RECOVER ANY OF
THE USER DATA IN THE CASE OF PASSWORD LOSS. THIS IS PRINCIPLE OF THE CRYPTOGRAPHIC DESIGN OF THE SYSTEM. IN NO EVENT SHALL ${owner} OR ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION) ARISING OUT OF THE DATA LOSS FOR ANY REASON INCLUDING BUT NOT LIMITED TO PASSWORD LOSS, PASSWORD STEALING OR DATA CORRUPTION EVEN IF ${owner} HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE LICENSEE AGREES TO TAKE THE RESPONSIBILITY OF KEEPING THE PASSWORD SAFE, NOT LOOSING IT AND NOT DISCLOSING TO THER PARRTIES.
 
## Explanation

The system has no access to the data, this is made purposedly. The user's password holders are the only persons who can access the data or change the password, as password change includes re-encryption of system objects that could not be performed without decrupting them with existing keys. 

## Recommendations to select the password

We recommend to create a ranfom password from letters of both lower and upper cases, numbers and at least one puntuation letters. The service provides basic password strength calculator, which gives good estimations _if the password is not made of words or keyboard order sequences_. While password strength estimator tries to forbid too weak combination, it is easy to bypass its check, but we strongly recommend not to do it. The rules is following:

- __never use words__. Even famous "three words" combination is way too weak comparing to 10-12 characters random password. Also workds with mutated characters like 10x or 4ever are too easy to discover for modern hacking tools.

- __never use dates__, even mutated or wicth digits/characters substitutions.

- __never use phone numbers__, also in combinations.

- __never use keboard sequences__ like \`qazxcvfrew\` - easy to remember usually means easy to crack.

- __use only english letters of both lower and upper cases, digits and puctuations.__ Try to get estimated bits strength above 180. English letters allow you to get your data from any device with any installed language. You never know when and where you'll need it. 


__BY SELECTING THE “ACCEPT” BUTTON, YOU ACKNOWLEDGE THAT: YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THE TERMS AND CONDITIONS OF THIS AGREEMENT.__
`
});

