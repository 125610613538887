











import { Component, Model, Prop, Vue } from "vue-property-decorator";

@Component
export default class WaitScreen extends Vue {
  @Prop({default: 0}) progress!: number;
  @Prop() title!: string;
  @Prop({default: null}) message!: string | null;
  @Prop({default: 1}) speed!: number;
}
