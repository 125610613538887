















import { Component, Vue } from "vue-property-decorator";
import { textState } from "@/lib/uitools";
import { ifLocalTest } from "@/lib/tools";
import WaitOverlay from "@/components/WaitOverlay.vue";
import XCheckbox from "@/components/XCheckbox.vue";
import { errorToast, infoToast } from "@/lib/toasts";
import { Notes } from "@/myonly.notes/Notes";

@Component({
  components: { WaitOverlay, XCheckbox }
})
export default class SignIn extends Vue {
  login = ifLocalTest("notest_1", "");
  password = ifLocalTest("12345qwert!");
  // password = ifLocalTest("qwert12345.");
  loading = false;
  wrongLogin = false;
  rememberMe = false;
  progressTitle = "";

  get loginState(): boolean | null {
    return textState(this.login, 4);
  }

  get passwordState(): boolean | null {
    return textState(this.password, 6);
  }

  async signIn() {
    console.log(this.rememberMe);
    this.wrongLogin = false;
    try {
      this.progressTitle = "Warming up...";
      this.loading = true;
      if( !await Notes.signIn(this.login,this.password,this.rememberMe, (msg:string) => this.progressTitle = msg ))
        this.wrongLogin = true;
    }
    catch(e) {
      errorToast(this, (e as any).message);
      console.error("sign in failed", e);
    }
    finally {
      this.loading = false;
    }
  }
}
