




















































import { Component, Vue } from "vue-property-decorator";
import MenuLayout from "@/components/MenuLayout.vue";
import { Notes } from "@/myonly.notes/Notes";
import { MyoNote, MyoNoteEvent } from "@/myonly.notes/MyoNote";
import { EmitterHandle } from "uparsecjs/dist/Emitter";
import NoteCard from "@/components/NoteCard.vue";
import { insertIntoSortedArray } from "@/lib/binary_search";
import { LocallyStoredString } from "@/lib/tools";

@Component({
  components: { NoteCard, MenuLayout }
})
export default class NotesGrid extends Vue {

  notes: MyoNote[] = [];
  pinnedNotes: MyoNote[] = [];
  search = "";
  title = "Notes";

  handle: EmitterHandle | undefined;

  addNote(note: MyoNote) {
    // insert in a proper place?
    this.notes.unshift(note);
  }

  private nn(note: MyoNote): MyoNote[] {
    return note.isPinned ? this.pinnedNotes : this.notes
  }

  selectClasses(showNotes: boolean): string[] {
    if (showNotes == !this._showTrash)
      return ['current'];
    return [];
  }

  filteredNotes(pinned = false): MyoNote[] {
    if (!this.search) return pinned ? this.pinnedNotes : this.notes;
    const parts = this.search.toLowerCase().split(/\s+/).map(x => x.trim()).filter(x => x != "");
    const nn = pinned ? this.pinnedNotes : this.notes;
    return nn.filter(note => {
      return parts.every(s => note.text.toLowerCase().includes(s) || note.title?.toLowerCase()?.includes(s));
    })
  }

  togglePinNote(note: MyoNote) {
    const from = this.nn(note);
    note.isPinned = !note.isPinned;
    const to = this.nn(note);

    const i = from.findIndex(x => x.uniqueTag == note.uniqueTag);
    if (i >= 0) from.splice(i, 1);

    insertIntoSortedArray(to, note, (a, b) => a.updatedAt.getTime() - b.updatedAt.getTime());
    note.isDirty = true;
    Notes.db?.saveNote(note);
  }

  findExistingNote(note: MyoNote): { array: MyoNote[], index: number } | undefined {
    let index = this.notes.findIndex(x => x.uniqueTag == note.uniqueTag);
    if (index >= 0) return { array: this.notes, index };
    index = this.pinnedNotes.findIndex(x => x.uniqueTag == note.uniqueTag);
    if (index >= 0) return { array: this.pinnedNotes, index };
    return undefined;
  }

  private _showTrash: boolean | null = null;
  private savedShowTrash = new LocallyStoredString("showTrash", "false");

  get showTrash() {
    return this._showTrash ?? false;
  }

  set showTrash(value: boolean) {
    if (this._showTrash != value) {
      this._showTrash = value;
      this.savedShowTrash.value = value.toString();
      this.title = value ? "Recycle" : "Notes";
      if (this.handle) this.handle.unsubscribe();
      this.notes = [];
      this.pinnedNotes = [];
      this.handle = Notes.db?.subscribeAll((e: MyoNoteEvent) => {
        if (e.note.trashed == this._showTrash) {
          const existing = this.findExistingNote(e.note);
          if (existing)
            existing.array.splice(existing.index, 1);
          switch (e.type) {
            case "updated":
              // we have delete it anyway
              insertIntoSortedArray(this.nn(e.note), e.note, (a, b) => a.updatedAt.getTime() - b.updatedAt.getTime());
              break;
            case "deleted":
              // nothing to do it is already deleted
              break;
          }
        }
      });
      Notes.refreshNotes();
    }
  }

  async clearTrashcan() {
    console.log("clearing the trashcan");
    const nn = new Array<MyoNote>();
    if (Notes.db) {
      for await (const note of Notes.db.trashed()) {
        note.setDeleted();
        nn.push(note);
      }
      for (const note of nn)
        await Notes.db.saveNote(note);
    }
  }

  mounted() {
    console.log("initializing");
    this.showTrash = this.savedShowTrash.value == "true";
  }

  beforeDestroy() {
    this.handle?.unsubscribe();
  }

}
