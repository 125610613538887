import { render, staticRenderFns } from "./LoggedIn.vue?vue&type=template&id=4b8b39d8&scoped=true&lang=pug&"
import script from "./LoggedIn.vue?vue&type=script&lang=ts&"
export * from "./LoggedIn.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b8b39d8",
  null
  
)

export default component.exports