import { Vue } from "vue-property-decorator";
import {BvToast} from "bootstrap-vue";


export function infoToast(self: Vue,text: string, title?: string): void {
  self.$bvToast.toast(text, {
    title: title,
    solid: true,
    autoHideDelay: 5000,
    toaster: 'b-toaster-bottom-right',
    variant: 'success'
  })
}

export function errorToast(self: Vue,text: string, title = "Error:"): void {
  self.$bvToast.toast(text, {
    title: title,
    autoHideDelay: 9000,
    toaster: 'b-toaster-top-right',
    solid: true,
    variant: 'danger'
  })
}

export function warningToast(self: Vue,text: string, title = "Warning:"): void {
  self.$bvToast.toast(text, {
    title: title,
    autoHideDelay: 9000,
    toaster: 'b-toaster-top-left',
    variant: 'warning'
  })
}
