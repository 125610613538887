



















import {Component,Vue} from "vue-property-decorator";
import {DOApi} from "@/digitalocean/DOApi";
import {DOToken} from "../../tests/unit/DO_data";
import { DropletInfo } from "../digitalocean/droplets";

@Component
export default class Droplets extends Vue {

  api = new DOApi(DOToken);

  droplets: DropletInfo[] = [];

  extractIP(info: DropletInfo,ipType: string): string {
    try {
      return info.networks.v4.find(x => x.type == ipType)?.ip_address ?? "";
    }
    catch(e) {
      console.log("error in", ipType, info);
      console.log(e);
      return "";
    }
  }

  turnOn(d: DropletInfo) {
    console.log(d.id);
    this.api.turnOn(d.id);
  }

  mounted() {
    this.api.allDroplets().then(dd => this.droplets = dd.filter(x => x.status!="active"));
  }

  async startAll() {
    for( let d of this.droplets) {
      console.log(`starting ${d.name}: ${d.id}`);
      await this.api.turnOn(d.id);
    }
  }
}
