

















































































import { Component, Prop, Vue } from "vue-property-decorator";
import CryptoTitle from "@/components/CryptoTitle.vue";
import { Notes } from "@/myonly.notes/Notes";
import { EmitterHandle } from "uparsecjs/dist/Emitter";

export interface MenuActionSeparator {
  separator: true
}
export interface MenuActionIcon {
  icon?: string;
  variant?: string;
  text: string;
  key?: string;
  callback: ()=>any;
}

export type MenuAction = MenuActionIcon | MenuActionSeparator;

let packageJsonInfo = require("../../package.json");
const ZeVersion = `${packageJsonInfo.version}β`;
// const ZeVersion = `0.87β`;

@Component({
  components: { CryptoTitle }
})
export default class MenuLayout extends Vue {

  @Prop() title?: string;
  @Prop({default: ""}) backstyle!: string;
  @Prop({default: ()=>[]}) actions!: MenuAction[];
  @Prop() showBack?: boolean;
  @Prop() fluid?: boolean
  @Prop({default: false}) showDrawer!: boolean

  appVersion = ZeVersion;

  loggedIn = false;

  private stateHandle?: EmitterHandle;

  get currentTitle() { return this.title ?? "ZeNotes "+ZeVersion; }

  get xxx(): string {
    return "--> "+Notes.loggedInEvent.state;
  }

  signOut() {
    Notes.signOut();
    this.$root.$emit('bv::toggle::collapse', 'drawer');
  }

  tooltip(a: MenuAction): string | null {
    if( 'separator' in a ) return null;
    return a.key ? `${a.text} (${a.key})` : a.text;
  }

  mounted() {
    this.stateHandle = Notes.loggedInEvent.addListenerAndNotify(s => {
      this.loggedIn = s;
    })
  }

  beforeDestroy() {
    this.stateHandle?.unsubscribe();
    this.stateHandle = undefined;
  }

}
