

































import { Component, Vue } from "vue-property-decorator";
import MenuLayout from "../components/MenuLayout.vue";
import { LegalDocs } from "@/docs/LegalDocs";
import { ConsentDoc } from "@/docs/ConsentDoc";
import CryptoTitle from "@/components/CryptoTitle.vue";

@Component({
  components: { CryptoTitle, MenuLayout }
})
export default class Info extends Vue {

  docs: Record<string, ConsentDoc> = LegalDocs;

  async withdraw(d: ConsentDoc) {
    if( d.isAccepted ) {
      if( await this.$bvModal.msgBoxConfirm(
          `Do you really want to withdraw acceptance of the "${d.name}"?`,
          {centered: true})) {
        d.withdraw();
        this.docs = {...this.docs };
      }
    }
  }
}

