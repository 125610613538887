import { Folder } from "@/model/Folder";
import { MyoNote } from "@/myonly.notes/MyoNote";
import { Notes } from "@/myonly.notes/Notes";
import { EmitterHandle } from "uparsecjs/dist/Emitter";

export class SearchFolder extends Folder<MyoNote> {
  private readonly parts: string[];
  private handle?: EmitterHandle;

  constructor(pattern: string) {
    super();
    this.parts = pattern.toLowerCase().split(/\s+/).filter(x => x != "");
    Notes.dbp.then(db => {
      this.handle = db.allNotes.addListener(event => {
        const item = event.item;
        switch (event.type) {
          case "deleted":
            this.removeItem(item);
            break;
          case "added":
          case "changed":
            let matches = false;
            for (const s of (item.text + " " + item.title).toLowerCase().split(/\s+/)) {
              for (const p of this.parts) {
                if (s.startsWith(p)) {
                  matches = true;
                  break;
                }
              }
              if( matches ) break;
            }
            if (matches)
              this.addItem(item);
            else
              this.removeItem(item);
            break;
        }
      });
    });
  }

  close() {
    this.handle?.unsubscribe();
    this.handle = undefined;
    this.clear();
  }
}
