









import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { requireConsent } from "@/docs/LegalDocs";
import Consent from "@/components/Consent.vue";
@Component({
  components: { Consent }
})
export default class XCheckbox extends Vue {
  @VModel() checked!: boolean;
  @Prop() consent?: string;

  noConsent(event: Event) {
    this.checked = false;
  }

}
