



























import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { firstSentence } from "@/lib/StringTools";
import { Notes } from "@/myonly.notes/Notes";
import { MyoNote } from "@/myonly.notes/MyoNote";
import { errorToast, infoToast } from "@/lib/toasts";
import { MenuAction } from "@/components/MenuLayout.vue";
import { Passwords, timeout } from "uparsecjs";
import { RouterExtra } from "@/router";

function insertAtCursor(myField: HTMLInputElement, myValue: string) {
  //IE support
  const docsel = (window.document as unknown as any).selection
  if (docsel) {
    myField.focus();
    const sel = docsel.createRange();
    sel.text = myValue;
  }
  //MOZILLA and others
  else if (myField.selectionStart || myField.selectionStart == 0) {
    let startPos = myField.selectionStart;
    let endPos = myField.selectionEnd!;
    myField.value = myField.value.substring(0, startPos)
        + myValue
        + myField.value.substring(endPos, myField.value.length);
    myField.selectionStart = startPos + myValue.length;
    myField.selectionEnd = myField.selectionStart;
  } else {
    myField.value += myValue;
  }
}


@Component
export default class NoteEditor extends Vue {

  @Prop() note!: MyoNote;

  titleIsSet: boolean = false;
  initialText = "";
  initialTitle?: string;

  get isChanged(): boolean {
    return this.note && (this.initialText != this.note.text || this.initialTitle != this.note.title);
  }

  async save() {
    if (this.note.text == "" && !this.note.title) {
      if (this.note.couldBeInCloud)
        errorToast(this, "Either enter some text or delete note");
      else {
        this.canSafelyGoBack();
        this.$router.back();
      }
    } else {
      if (this.initialText != this.note.text || this.initialTitle != this.note.title) {
        this.note.isDirty = true;
        this.note.updatedAt = new Date();
        Notes.db?.saveNote(this.note);
        // Notes.db?.deleteDraftFor(this.note);
        // this.draft = null;
      }
      this.canSafelyGoBack();
      this.$router.back();
    }
  }

  generatePassword() {
    const field = this.$refs.nt1 as HTMLInputElement;
    let password;
    console.log("creacting password 14/128");
    do {
      // we want it to be compatible to most systems:
      password = Passwords.create(12, 128, true);
      // we don't want `` to be in the password:
    } while(password.includes("``"));
    insertAtCursor(field, `\`\`${password}\`\` `);
    // (this.$refs.nt1 as Vue).$emit('input', field.value)
    this.note.text = field.value;
  }

  // async destroy() {
  //   if (confirm("Are you sure you want to delete this note? It can't be rolled back")) {
  //     this.note.setDeleted();
  //     await Notes.db?.saveNote(this.note);
  //     this.$router.back();
  //   }
  // }

  async toggleRecycled() {
    this.note.isDirty = true;
    if (this.note.trashed) {
      this.note.trashed = false;
      await Notes.db?.saveNote(this.note);
      this.canSafelyGoBack()
      this.$router.back();
      infoToast(this.$parent, "Note has been restored", "Deleted note has been restored");
    } else {
      this.note.trashed = true;
      await Notes.db?.saveNote(this.note);
      this.canSafelyGoBack()
      this.$router.back();
      infoToast(this.$parent, "Note has been moved to recycle bin", "Note has been deleted");
    }
  }

  canSafelyGoBack() {
    RouterExtra.blockBack = false;
  }

  async cancel() {
    if (this.isChanged && !(
        await this.$bvModal.msgBoxConfirm("Discard all changes?", { centered: true })
    ))
      return;
    this.note.text = this.initialText;
    this.note.title = this.initialTitle;
    // const d = this.draft;
    // if( d ) {
    //   Notes.db?.deleteDraftNote(d)
    //   this.draft = null;
    // }
    this.canSafelyGoBack()
    this.$router.back();
  }

  get actions(): MenuAction[] {
    const n = this.note;
    const result: MenuAction[] = [];
    if (n) {
      result.push(
          // { icon: "x-circle-fill", variant: 'warning', text: 'delete', callback: () => this.destroy() },
          { icon: "lock", text: 'new password', key: 'ctrl-g', callback: () => this.generatePassword() },
          {
            icon: n.isPinned ? "star-fill" : "star",
            text: n.isPinned ? 'unpin' : 'pin',
            callback: () => this.togglePin()
          },
          { separator: true },
          {
            icon: n.trashed ? "trash-fill" : "trash",
            text: n.trashed ? 'restore' : 'delete',
            key: 'cmd-⌫',
            callback: () => this.toggleRecycled()
          }
      );
    }

    if (this.isChanged) {
      result.unshift({ icon: "arrow-counterclockwise", text: 'cancel', key: 'cmd-esc', callback: () => this.cancel() })
    }
    return result;
  }

  togglePin() {
    this.note.isPinned = !this.note.isPinned;
    this.note.isDirty = true;
    Notes.db?.saveNote(this.note);
  }

  @Watch("note")
  notePropChanged() {
    if (this.note) {
      this.titleIsSet = true;
      this.initialTitle = this.note.title;
      this.initialText = this.note.text;
      Notes.db?.getOrCreateDraft(this.note).then( n => {
        // this.draft = n ?? null;
        console.log("draft loaded")
        if( n ) {
          this.note.text = n.text;
          this.note.title = n.title;
          console.log("fields reset to draft")
        }
      });
    }
  }

  saveDraft() {
    // const d = this.draft;
    // if( d ) {
    //   d.title = this.note.title;
    //   d.text = this.note.text;
    //   console.log("updating draft:", d);
    //   Notes.db?.saveNote(d);
    // }
    // else console.warn("draft is not set");
  }

  // draft: MyoNote | null = null;

  async mounted() {
    // this is a bug that sometimes appear in some browsers
    await timeout(40);
    (this.$refs.nt1 as HTMLElement | undefined)?.focus();
    RouterExtra.blockBack = true;
  }

  beforeUnmount() {
    RouterExtra.blockBack = false;
  }
}
