import { CachedSessionStorage } from "uparsecjs/dist/CachedMemoryStorage";
import { SymmetricKey, unicryptoReady } from "unicrypto";
import { AnnotatedKey, Credentials } from "myonlycloud";
import { PasswordKeyGenerator } from "myonlycloud/dist/PasswordKeyGenerator";
import { RememberMeStorage } from "@/lib/RememberMeStorage";
import { EncryptedSessionStorage } from "uparsecjs/dist/EncryptedSessionStorage";
import { bytesToUtf8, ParsecSessionStorage, utf8ToBytes } from "uparsecjs";


async function deriveStorageKey(password: string): Promise<SymmetricKey> {
  const keys = await Credentials.deriveKeysFromPassword(password);
  // storage key is generatoed from the same password, but using storage key (also derived from this password)
  // as a salt. this is paranoid but protect the storage key from being disclosed by cracking storage key, as the
  // storage key could be disclosed in "remember me" mode on most devices:
  const ak: AnnotatedKey = (await PasswordKeyGenerator.generateKeys(password,
    1,
    await keys.storageKey.packedKey,
    500,
    "SHA3_256"))[0];
  return ak.key as SymmetricKey;
}

export class SmartSessionStorage {

  readonly #storage = new CachedSessionStorage();

  #encryptedStorage?: EncryptedSessionStorage;

  /**
   * Construct smart storage backed by a given permanent storage. Any data in it will be heavily encrypted.
   * @param permanentStorage
   */
  constructor(private readonly permanentStorage: Storage) {
  }

  /**
   * Storage to use. It is automatically managed to connect/disconnect.
   */
  get storage(): ParsecSessionStorage {
    return this.#storage;
  }

  /**
   * Encrypt storage (copying its current state) and pemranently save in the system `localStorage`. From now on any changes
   * will be reflected in encrypted manner in the permanent storage. Note that it will overwrite any encrypted storage that
   * may exist.
   * @param password
   * @param rms of present, will be used to save the encryption key.
   */
  async encrypt(password: string, rms?: RememberMeStorage): Promise<void> {
    if (this.#storage.isConnected) this.#storage.disconnect();
    this.#encryptedStorage?.close();
    const key = await deriveStorageKey(password);
    EncryptedSessionStorage.clearIn(this.permanentStorage);
    await unicryptoReady;
    this.#encryptedStorage = new EncryptedSessionStorage(this.permanentStorage, key);
    this.#storage.connectToStorage(this.#encryptedStorage);
    if (rms) await rms.remember({ key });
  }

  /**
   * Decrypt from [[RememberMeStorage]] instance. Disconnect any encrypted storage if any. Does not affect RMS
   * if fails - caller should consider clearing it.
   * @param rms storage to extract key from.
   */
  async decryptWithRMS(rms: RememberMeStorage): Promise<boolean> {
    this.disconnect();
    try {
      const data = await rms.recall<{ key: SymmetricKey }>();
      if (data && data.key ) {
        await unicryptoReady;
        // HACK: ,aybe we should await earlier?
        const key = new SymmetricKey({keyBytes:data.key.pack()});
        data.key = key;
        this.#encryptedStorage = new EncryptedSessionStorage(this.permanentStorage, key);
        this.#storage.connectToStorage(this.#encryptedStorage);
        return true;
      }
      console.warn("SSS: RMS: invalid format");
    } catch (e) {
      console.warn("SSS: RMS decryption failed", e);
    }
    return false;
  }

  /**
   * Decrypt permanent storage using the password.
   * @param password
   * @param rms if present, will be used to save the key if the password is ok.
   * @return true if storage is decrypted and connected.
   */
  async decryptWithPassword(password: string, rms?: RememberMeStorage): Promise<boolean> {
    const key = await deriveStorageKey(password);
    this.disconnect();
    try {
      await unicryptoReady;
      this.#encryptedStorage = new EncryptedSessionStorage(this.permanentStorage, key);
      this.#storage.connectToStorage(this.#encryptedStorage);
      if (rms) await rms.remember({ key });
      return true;
    }
    catch(e) {
      console.warn("failed to restore storage from the password");
    }
    return false;
  }

  /**
   * Check that there possibly exist an encrypted storage and decryption could be possible.
   * If it returns false, decryption will always fail.
   */
  get couldBeDecrypted(): boolean {
    return EncryptedSessionStorage.existsIn(this.permanentStorage);
  }

  /**
   * Remove from permanent store any existing encrypted data. It also disconnects encrypted storage if
   * it is already connected using [[encryptedStorage]].
   */
  clearPermanentStorage() {
    this.disconnect();
    EncryptedSessionStorage.clearIn(this.permanentStorage);
  }

  private disconnect() {
    if (this.#storage.isConnected) this.#storage.disconnect();
    this.#encryptedStorage?.close();
    this.#encryptedStorage = undefined;
  }
}
