import StateEmitter from "@/lib/StateEmitter";
import { z784 } from "@/lib/storage_tools";
import { Credentials, MyoCloud, RegistrationResult } from "myonlycloud";
import { EncryptedSessionStorage } from "uparsecjs/dist/EncryptedSessionStorage";
import { decode64, encode64, SymmetricKey } from "unicrypto";
import { CompletablePromise, concatenateBinary, utf8ToBytes } from "uparsecjs";
import { MyoNotes } from "@/myonly.notes/MyoNotes";
import { c467 } from "@/lib/uitools";
import { RememberMeStorage } from "@/lib/RememberMeStorage";
import { SmartSessionStorage } from "@/lib/SmartSessionStorage";
import { MutexCallback } from "@/lib/MutexCallback";
import { MyoNote } from "@/myonly.notes/MyoNote";
import { EmitterHandle } from "uparsecjs/dist/Emitter";

const ES_READY_KEY = "RFUj$7Sshul#8895!0767";

const ES_REAY_VALUE = "14";

const salt7 = utf8ToBytes("1XBUy2LXsACDafSq8c0Qb8Bhe0pI69rUznhGKZl5vlJkHcaK1ZICOlbjWBCQCoyJsxB57ypLGcs5c4o_MHoSbYTDLrR2xYIWcz6WcEOilhMvLJoRzCdW2");

export class Notes {

  // making life hard: splitting part of the salt
  private static rememberMeStorage = new RememberMeStorage(
    "sessionCookie", concatenateBinary(salt7, c467(), z784)
  );

  private static smartStorage = new SmartSessionStorage(window.localStorage);

  static loggedInEvent = new StateEmitter<boolean>(false);

  static connectedEvent = new StateEmitter<boolean>(false);
  private static senderHandle?: EmitterHandle;

  private static get storage() {
    return this.smartStorage.storage;
  }

  static _service: MyoCloud | null = null;

  static db: MyoNotes | null = null;

  static get service(): MyoCloud {
    if (!this._service) throw new Error("service is not initialized")
    return this._service;
  }

  static async connect(): Promise<void> {
    if (this._service == null) {
      this._service = new MyoCloud(this.storage, { serviceAddress: "https://api.myonly.cloud/api/p1" });
      // await this.service.connected;
      this._service.connected.then(()=>this.sender.perform());
      console.log("last serial: "+await this.db?.getLastNoteSerial());
    }
    await this.service.connected;
  }

  private static async stepSend() {
    let dirty;
    do {
      if( !this.db || !this._service) return;
      dirty = await this.db.getDirtyNote();
      let note = dirty.note;
      if (note) {
        await this._service.connected;
        console.log("sending note", note);

        note.couldBeInCloud = true;
        await this.db.saveNote(note);

        const element = await this.service.setByUniqueTag(await note.packToCloud(this._service));

        console.log("note sent tp cloud:", note);

        note.isDirty = false;
        note.cloudId = element.id;
        note.serial = element.serial;
        note.revision = element.revision;
        await this.db.saveNote(note);
      }
    } while(dirty.hasMore)
  }

  // private static async stepDelete() {
  //   if( !this.db || !this._service) return;
  //   const deleteIds = await this.db?.deleteQueue.toArray();
  //   for( const x of deleteIds) {
  //     const uniqueTag = x.uniqueTag;
  //     console.log("deleting "+uniqueTag);
  //     await this.service.deleteByUniqueTag(uniqueTag);
  //     console.log("deleted "+uniqueTag);
  //     await this.db?.deleteQueue?.delete( uniqueTag);
  //   }
  // }

  static get notesTag1(): Promise<string> {
    return (async()=>{
      return await this.service.scramble("MyoNote");
    })();
  }

  private static refresher = new MutexCallback(async () => {
    if( !this.db || !this._service) return;
    if( !this.loggedInEvent.state ) {
      console.log("REFR: not logged in, ignore");
    }
    try {
      const afterSerial = await this.db.getLastNoteSerial();
      // console.log(await this.service.checkConnection())
      const elements = await this._service.elementsByTags({ tag1: await this.notesTag1, afterSerial });
      console.log(`refresh received ${elements.length} elements after ${afterSerial}`);
      for (const e of elements) {
        try {
          this.db.saveNote(await MyoNote.unpackFrom(e));
        } catch (x) {
          console.warn("failed to unpack note " + e.uniqueTag, x);
          // console.log("will delete");
          // await this.db.deleteQueue.put({uniqueTag: e.uniqueTag!,deleteRequestedAt: new Date()});
        }
      }
      console.log("refresh done");
      // this.sender.perform();
    }
    catch(x) {
      console.error("Exception in refresher, will reschedule", x);
      this.refresher.performLater(3000);
    }
  });

  static refreshNotes(timeoutMillis?: number) {
    if( timeoutMillis ) this.refresher.performLater(timeoutMillis);
    else this.refresher.perform(false);
  }

  private static sender = new MutexCallback(async () => {
    if (this._service && this.db) {
      await this.service.connected;
      await this.db.ready;
      // await this.stepDelete();
      await this.stepSend();
    }
  });

  static async signIn(login: string,
                      password: string,
                      rememberMe = true,
                      callback?: (text: string) => void
                      ): Promise<boolean> {
    try {
      callback?.("Establishing PARSEC connection...");
      await this.service.connected;
      callback?.("Loggin in...");
      await this.service.login(login, password)
    } catch (x) {
      if( x instanceof MyoCloud.InvalidPassword ) {
        console.log("Can't decrypt: invalid password");
        return false;
      }
      const e = x as any;
      if ("code" in e && e.code == "not_found") {
        console.log("not found means bad password for us");
        return false;
      }
      console.warn("login error (unexpected):", e);
      throw e;
    }
    console.log("after login check", await this.service.checkConnection());
    await this.smartStorage.encrypt(password, rememberMe ? this.rememberMeStorage : undefined);
    this.storage.setItem("login", login);

    window.localStorage.setItem(ES_READY_KEY, ES_REAY_VALUE);
    this.initializeDB();
    this.loggedInEvent.state = true;
    this.connectedEvent.state = true;

    this.showProtocolVars();

    return true;
  }

  private static showProtocolVars() {
    const es = this.storage;
    console.log("Session data SCK: " + es.getItem(".p1.SCK"))
    console.log("Session data TSK:" + es.getItem(".p1.TSK"))
    console.log("Session data SID " + es.getItem(".p1.SID"))
  }

  static async signUp(login: string, password: string, rememberMe = false,
                      callback?: (text: string) => void): Promise<RegistrationResult> {
    callback?.("PARSEC is connecting...")
    await this.service.connected;
    callback?.("Creating registration...")
    const result = await this.service.register(login, password);
    if (result == "OK") {
      callback?.("Logging in...")
      await this.signIn(login, password, rememberMe);
    }
    return result;
  }

  /**
   * Local sign out, does not affect server profile
   */
  static async signOut() {
    await this.service.call("signOut");
    await this.deleteLocalData();
    this.connectedEvent.state = true;
    this.loggedInEvent.state = false;
  }

  static async checkLoginAvailable(login: string): Promise<boolean> {
    const result = await this.service.call("checkLoginAvailable", {
      loginHash: await Credentials.deriveLoginHash(login)
    });
    console.log(result);
    return result.available as boolean;
  }

  static get hasStoredSession(): boolean {
    return window.localStorage.getItem(ES_READY_KEY) == ES_REAY_VALUE && EncryptedSessionStorage.existsIn(window.localStorage);
  }

  static async deleteLocalData() {
    this.smartStorage.clearPermanentStorage();
    window.localStorage.removeItem(ES_READY_KEY);
    this.rememberMeStorage.clear();
    await this.db?.clearData();
    await this.db?.close();
    this.db = null;
    this.dbp = new CompletablePromise();
    console.log("local data deleted");
  }

  private static initializeDB() {
    // We are super paranoid and will use separate key to encrypt database
    let dbKey: SymmetricKey | undefined;
    let packed = this.storage.getItem("dbKey");
    if (packed)
      try {
        dbKey = new SymmetricKey({ keyBytes: decode64(packed) });
      } catch (x) {
        console.warn("failed to decrypt DB key in storage, storage will be cleared!")
      }
    if (!dbKey) {
      dbKey = new SymmetricKey();
      this.storage.setItem("dbKey", encode64(dbKey.pack()));
    }
    this.db = new MyoNotes("MyoNotes.DB", dbKey);
    this.dbp.resolve(this.db);
    if( this.senderHandle ) {
      this.senderHandle.unsubscribe();
      this.senderHandle = undefined;
    }
    this.db.loadNotesFromDatabase().then(() => {
      console.log("schedule refresher and sender");
      this.refresher.performLater(10);
      this.sender.performLater(500);
      this.senderHandle = this.db?.subscribeAll(x => {
        if( x.type != "deleted" && x.note.isDirty ) this.sender.performLater(500);
      })
    });
  }

  static dbp = new CompletablePromise<MyoNotes>();


  /**
   * Try to restore settings from the saved encrypted storage. Check [[hasStoredSession]] first and
   * [[tryRestoreRememberedSession]] without password, and if it fails, require password and call this.
   *
   * @return true if the session is restored, false means wrong password. Be sure to check [[hasStoredSession]] first.
   *
   * @param password that was used to log in to the session
   * @param rememberMe use remember me storage to automatically restore state (less safe)
   */
  static async tryRestoreSettings(password: string, rememberMe = false)
    : Promise<boolean> {
    try {
      if( !await this.smartStorage.decryptWithPassword(password, rememberMe ? this.rememberMeStorage : undefined) )
        return false;
    } catch (e) {
      return false;
    }
    return this.setLoggedInState();
  }

  /**
   * Set all settings when it is known to be logged in, even when there is yet no
   * netwirk connection.
   * @private
   */
  private static setLoggedInState() {
    this.initializeDB();

    // assume it is ok as check returned without exceptions:
    this.loggedInEvent.state = true;

    this.connect().then(() => this.connectedEvent.state = true);

    return true;
  }

  /**
   * Try to restore session saved in "Remember Me" mode. Check [[hasStoredSession]] first, then call it. If there
   * is a valid remember me session, it will be restored. Otherwise try to call [[tryRestoreSettings]] which
   * requires password.
   *
   * @return true if the session is restored, false if it is not possible.
   */
  static async tryRestoreRememberedSession(): Promise<boolean> {
    if (this.connectedEvent.state || this.loggedInEvent.state)
      return true;
    if (await this.smartStorage.decryptWithRMS(this.rememberMeStorage)) {
      console.log("RMS based restore OK");
      return this.setLoggedInState();
      // return this.restoreWithLocalStorage(callback);
    } else {
      console.log("RMS failed or not exist");
      this.rememberMeStorage.clear();
      return false;
    }
  }

}

const refreshTimer = setInterval(()=>{
  if( Notes.connectedEvent.state && Notes.loggedInEvent.state ) {
    Notes.refreshNotes();
  }
}, 15000);

(window as unknown as any).Notes = Notes;
(window as unknown as any).ESS = EncryptedSessionStorage;
