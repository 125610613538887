




import { Component, Vue, Watch } from "vue-property-decorator";
import { MyoNote } from "@/myonly.notes/MyoNote";
import NoteEditor from "@/components/NoteEditor.vue";

MyoNote;

@Component({
  components: { NoteEditor }
})
export default class NewNote extends Vue {

  note: MyoNote | null = null;

  async mounted() {
    this.note = new MyoNote({text:""});
  }
}
