



















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MenuLayout from "@/components/MenuLayout.vue";
import { Notes } from "@/myonly.notes/Notes";
import { MyoNote } from "@/myonly.notes/MyoNote";
import { EmitterHandle } from "uparsecjs/dist/Emitter";
import NoteCard from "@/components/NoteCard.vue";
import { insertIntoSortedArray } from "@/lib/binary_search";
import { LocallyStoredString } from "@/lib/tools";
import { Folder } from "@/model/Folder";

@Component({
  components: { NoteCard, MenuLayout }
})
export default class ShowFolder extends Vue {

  @Prop({default: null}) folder!: Folder<MyoNote> | null;

  notes: MyoNote[] = [];
  pinnedNotes: MyoNote[] = [];
  title = "Notes";

  handle: EmitterHandle | undefined;

  private nn(note: MyoNote): MyoNote[] {
    return note.isPinned ? this.pinnedNotes : this.notes
  }

  selectClasses(showNotes: boolean): string[] {
    if (showNotes == !this._showTrash)
      return ['current'];
    return [];
  }

  filteredNotes(pinned = false): MyoNote[] {
    return pinned ? this.pinnedNotes : this.notes;
  }

  removeExisting(note: MyoNote) {
    const x = this.findExistingNote(note);
    if( x )
      x.array.splice(x.index,1);
  }

  insertOrReplaceNote(note: MyoNote) {
    this.removeExisting(note);
    insertIntoSortedArray(this.nn(note), note, (a, b) => a.updatedAt.getTime() - b.updatedAt.getTime());
  }

  togglePinNote(note: MyoNote) {
    note.isPinned = !note.isPinned;
    Notes.db?.saveNote(note);
    this.insertOrReplaceNote(note);
  }

  findExistingNote(note: MyoNote): { array: MyoNote[], index: number } | undefined {
    let index = this.notes.findIndex(x => x.uniqueTag == note.uniqueTag);
    if (index >= 0) return { array: this.notes, index };
    index = this.pinnedNotes.findIndex(x => x.uniqueTag == note.uniqueTag);
    if (index >= 0) return { array: this.pinnedNotes, index };
    return undefined;
  }

  private _showTrash: boolean | null = null;
  private savedShowTrash = new LocallyStoredString("showTrash", "false");

  // mounted() {
  //   if( Notes.db ) this.assignFolder(Notes.db.allNotes);
  // }

  beforeDestroy() {
    this.handle?.unsubscribe();
  }

  @Watch("folder", { immediate: true })
  private assignFolder(folder: Folder<MyoNote>) {
    if( this.handle ) this.handle.unsubscribe();
    this.notes = [];
    this.pinnedNotes = [];
    this.folder = folder;
    if( folder ) {
      this.handle = folder.addListener(event => {
        switch (event.type) {
          case "changed":
          case "added":
            this.insertOrReplaceNote(event.item)
            break;
          case "deleted":
            this.removeExisting(event.item);
            break;
        }
      });
    }
  }

}
