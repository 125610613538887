import { ConsentDoc } from "@/docs/ConsentDoc";
import { LegalIds } from "@/docs/LegalIds";

const owner = LegalIds.owner;
const product = LegalIds.product;

export const BPA_doc = new ConsentDoc({
  version: 1,
  name: "Beta Participation Agreement",
  id: "BPA",
  text: `
    
# Beta Participation Agreement

__Please read and agree to the following terms and conditions, if you wish to be eligible to participate in the Open Beta Testing.__ 

## DISCLAIMER

THE BETA SOFTWARE LICENSED HEREUNDER IS STILL IN TESTING PHASE AND IS PROVIDED ON AN “AS
IS” AND “AS AVAILABLE” BASIS AND IS BELIEVED TO CONTAIN DEFECTS AND A PRIMARY PURPOSE OF
THIS BETA TESTING LICENSE IS TO OBTAIN FEEDBACK ON SOFTWARE PERFORMANCE AND THE
IDENTIFICATION OF DEFECTS. LICENSEE IS ADVISED TO SAFEGUARD IMPORTANT DATA, TO USE
CAUTION AND NOT TO RELY IN ANY WAY ON THE CORRECT FUNCTIONING OR PERFORMANCE OF THE
BETA LICENSED SOFTWARE AND/OR ACCOMPANYING MATERIALS. THE SOFTWARE IS PROVIDED WITHOUT ANY EXPRESS OR IMPLIED WARRANTY OF ANY KIND INCLUDING WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE. IN NO EVENT SHALL ${owner} OR ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, BUSINESS INTERRUPTION, LOSS OF INFORMATION) ARISING OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE, EVEN IF ${owner} HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 

## Copyright and ownership

Ownership and Copyright of Software Title to the Software and all copies thereof remain with ${owner} and/or its suppliers. The Software is copyrighted and is protected by international copyright laws and international treaty provisions. Licensee will not remove copyright notices from the Software. Licensee agrees to prevent any unauthorized copying of the Software. Except as expressly provided herein, ${owner} does not grant any express or implied right to you under ${owner} patents, copyrights, trademarks, or trade secret information.

## License and Acceptable Use

Limited License. You are entitled to access, install as Progressive Web Application (PWA) for offline use, and operate the ${product} solely for the purposes of performing your obligations under this Agreement. You may not sell, license, or transfer the ${product}, or reproductions of the ${product} to other parties In any way.

## Term and Termination

Unless otherwise terminated as specified under this Agreement. Licensee’s rights with respect to the Beta Software will terminate upon the earlier of (a) the initial commercial release bby ${owner} of a generally available version of the Beta Software or (b) automatic expiration of the Beta Software based on the system date. Either party may terminate this Agreement at any time for any reason or no reason without notice. Upon any expiration or termination of this Agreement, the rights and licenses granted to Licensee under this Agreement shall immediately terminate, and Licensee shall immediately cease using the Beta Software, Documentation, and all other tangible items in Licensee’s possession or control that are proprietary to or contain Confidential Information. 

## No Support and Maintenance; Future Products.

During your participation in the Beta Program ${owner} is not obligated to provide you with any maintenance, technical or other support for the Beta and Pre-Release Software. If, at ${owner} option, such support is provided, you agree to abide by any support rules and policies that ${owner} provides to you in order to receive such support. You acknowledge that ${owner} has no express or implied obligation to announce or make available a commercial version of the Pre-Release and Beta Software to anyone in the future. Should a commercial version be made available, it may have features or functionality that are different from those found in the Pre-Release and Beta Software licensed hereunder.

## Privacy Policy

${product} Privacy Policy available at ${LegalIds.root}/privacy_policy (Privacy Policy) applies to the Beta Program and the Beta Software. You acknowledge and agree that by participating in the Beta Program or by using the Beta Software, theScore may receive certain information about you. including personally identifiable information. and you hereby consent to theScore’s collection, use and disclosure such information in accordance with the Privacy Policy.

-------------------------

__BY SELECTING THE “ACCEPT” BUTTON, YOU ACKNOWLEDGE THAT: (1) YOU ARE 13 YEARS OF AGE OR OLDER, AND IF YOU ARE BETWEEN AGE 13 and 18, YOU HAVE OBTAINED CONSENT FROM YOUR PARENT OR GUARDIAN; AND (2) YOU HAVE READ, UNDERSTOOD, AND ACCEPTED THE TERMS AND CONDITIONS OF THIS AGREEMENT.__
`
});

