














import { Component, Vue } from "vue-property-decorator";
import CryptoTitle from "@/components/CryptoTitle.vue";

@Component({
})
export default class App extends Vue {

  registration?: ServiceWorkerRegistration
  updateExists = false
  refreshing = false
  private updater?: any;

  updateAvailable(event: Event) {
    console.log("got an event: there is SW update from service worker",event)
    if( this.updater ) clearInterval(this.updater);
    this.updater = undefined;
    this.registration = (event as CustomEvent).detail
    this.updateExists = true
    this.$bvToast.show('reload-toast')
  }

  swRegistered(event: Event) {
    this.registration = (event as CustomEvent).detail
    console.log("got registration data from worker", this.registration);
    if( this.updater ) clearInterval(this.updater);
    this.updater = setInterval(() => {
      console.log("sending update to SW");
      this.registration?.update();
    }, 10000);
  }

  refreshApp() {
    this.updateExists = false
    this.$bvToast.hide('reload-toast')
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return
    // send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
  }

  mounted() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    document.addEventListener('swRegistered', this.swRegistered, { once: true })
    console.log("service worker listener added")
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      console.log("connrollerchange event caught")
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  }
}
