
export type NoConsentAction = "block" | "return";

export class ConsentDoc {
  version!: number;
  id!: string;
  name!: string;
  text!: string;

  constructor(props: Partial<ConsentDoc>) {
    Object.assign(this, props);
  }

  get docTag(): string {
    return `consent_${this.id}`;
  }

  accept() {
    localStorage.setItem(this.docTag, "" + this.version);
  }

  withdraw() {
    localStorage.removeItem(this.docTag);
  }

  get isAccepted(): boolean {
    const v = localStorage.getItem(this.docTag) ?? 0;
    return +v >= this.version;
  }

}

