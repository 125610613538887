import { MyoNote, MyoNoteEvent, scrambleDraftTag } from "@/myonly.notes/MyoNote";
import { MapSerializer, MyoCloud, Tags } from "myonlycloud";
import Dexie from "dexie";
import { randomBytes, SymmetricKey, unicryptoReady } from "unicrypto";
import { CompletablePromise, Emitter, EmitterHandle } from "uparsecjs";
import { Folder } from "@/model/Folder";


interface EncryptedBody {
  encryptedBody: Uint8Array;
}

interface IStoredNote extends Tags, EncryptedBody {
  isDirty?: string;
  draftOf?: string;
}

interface IDbParam extends EncryptedBody {
  name: string
}

// noinspection SpellCheckingInspection
const ENCRYPTION_MARK = "34GO9587ytZiu%lhfj#dksm,";
// noinspection SpellCheckingInspection
const ENCRYPTION_MARK_VALUE = "KjfRyghW45hy9E$%6j";

interface EncryptionParams {
  salt: Uint8Array;
  mark: string;
  storageKey: Uint8Array;
}

interface IDeleteQueueItem {
  uniqueTag: string;
  deleteRequestedAt: Date;
}

export class MyoNotes extends Dexie {

  readonly notes: Dexie.Table<IStoredNote, string>;
  readonly params: Dexie.Table<IDbParam, string>;
  readonly deleteQueue: Dexie.Table<IDeleteQueueItem, string>;

  #salt!: Uint8Array;
  #mainKey!: SymmetricKey;
  #accessKey: SymmetricKey;

  readonly ready = new CompletablePromise<void>();

  constructor(dbName: string, key: SymmetricKey) {
    super(dbName);
    this.version(1).stores({
      notes: "&uniqueTag,tag1,tag2,tag3,isDirty,draftOf",
      params: "&name",
      deleteQueue: "&uniqueTag"
    })
    this.notes = this.table("notes");
    this.params = this.table("params");
    this.deleteQueue = this.table("deleteQueue");
    this.#accessKey = key;
    this.initialize().then();
  }

  private async initialize() {
    // check our key can read our our data
    await unicryptoReady;
    if (await this.loadMainKey()) {
      console.log("notes db key is ok")
    } else {
      console.log("notes db needs new key, cleaning data")
      await this.clearData();
      console.log("notes db needs new key, creating key")
      await this.createMainKey();
    }
  }

  private cache = new Map<string, MyoNote>();

  async clearData(): Promise<void> {
    this.cache.clear();
    await this.notes.clear();
    await this.params.clear();
    await this.deleteQueue.clear();
    this.allNotes.clear();
    this.activeNotes.clear();
    this.deletedNotes.clear();
    console.log("tables are cleared:", await this.notes.count(), await this.params.count());
  }

  private async createMainKey() {
    const newKey = new SymmetricKey();
    const src: EncryptionParams = {
      storageKey: newKey.pack(),
      mark: ENCRYPTION_MARK_VALUE,
      salt: randomBytes(32)
    };
    const data = await this.#accessKey.etaEncrypt(await MapSerializer.toBoss(src));
    await this.params.put({ name: ENCRYPTION_MARK, encryptedBody: data });
    this.#salt = src.salt;
    this.#mainKey = newKey;
    this.ready.resolve();
  }

  private async loadMainKey(): Promise<boolean> {
    const m = await this.params.get(ENCRYPTION_MARK);
    if (!m) return false;
    try {
      const ep: EncryptionParams = await MapSerializer.anyFromBoss(await this.#accessKey.etaDecrypt(m.encryptedBody));
      if (ep.mark != ENCRYPTION_MARK_VALUE) {
        console.warn("encrypted db has invalid encryption mark");
        return false;
      }
      this.#salt = ep.salt;
      this.#mainKey = new SymmetricKey({ keyBytes: ep.storageKey });
      this.ready.resolve();
      return true;
    } catch (e) {
      console.warn("db decryption initial check failed", e);
    }
    return false;
  }

  async changeKey(newKey: SymmetricKey): Promise<void> {
    await this.ready;
    const m = await this.params.get(ENCRYPTION_MARK);
    if (!m) throw new Error("broken encrypted DB: no encryption mark");
    let packedEp = this.#accessKey.etaDecryptSync(m.encryptedBody);
    packedEp = newKey.etaEncryptSync(packedEp);
    await this.params.put({ name: ENCRYPTION_MARK, encryptedBody: packedEp });
    this.#accessKey = newKey;
  }

  async getNote(uniqueTag: string): Promise<MyoNote | undefined> {
    await this.ready;
    let note = this.cache.get(uniqueTag);
    if (note) return note;
    const isn = await this.notes.where({ uniqueTag }).first();
    return await this.unpack(isn);
  }

  async getDraft(noteId: string | undefined): Promise<MyoNote | undefined> {
    await this.ready;
    const draftOf = noteId ?? this.newNoteDraftId;
    const isn = await this.notes.where({ draftOf }).first();
    return isn ? await this.unpack(isn) : undefined;
  }

  async getDirtyNote(): Promise<{ note?: MyoNote, hasMore?: boolean }> {
    const packed = await this.notes.where({ isDirty: "true" }).limit(2).toArray();
    if (packed.length == 0) return {};
    return {
      note: await this.unpack(packed[0]),
      hasMore: packed.length > 1
    };
  }

  async saveNote(note: MyoNote): Promise<MyoNote> {
    // important: dirty note marked for deletion should not be deleted: it is ketp
    // until transmitted to the cloud.
    this.updateLastNoteSerial(note);
    if (note.markedForDeletion) {
      console.log("marked for deletion", note);
      if (!note.isDirty) {
        // deleted, sent to the cloud, now we can delete it
        console.log("note is sent, remove ot from database");
        this.noteChanged.fire({ note, type: "deleted" });
        await this.deleteNote(note)
        this.activeNotes.removeItem(note);
        this.allNotes.removeItem(note);
        this.deletedNotes.removeItem(note);
        return note
      }
      // here we just remove it from the queue
      console.log("deleted note is not set. we have to wait for it to be sent to queue")
      if (this.cache.has(note.uniqueTag)) {
        this.cache.delete(note.uniqueTag);
        this.noteChanged.fire({ note, type: "deleted" });
      }
    }
    await this.ready;
    this.cache.set(note.uniqueTag, note);
    await this.notes.put(await this.pack(note));
    this.noteChanged.fire({ note, type: "updated" });
    this.syncNoteFolders(note);

    return note;
  }

  private syncNoteFolders(note: MyoNote) {
    this.allNotes.addItem(note);
    if (note.trashed) {
      this.deletedNotes.addItem(note);
      this.activeNotes.removeItem(note);
    } else {
      this.deletedNotes.removeItem(note);
      this.activeNotes.addItem(note);
    }
  }

  private async deleteNote(note: MyoNote): Promise<void> {
    await this.ready;
    if (!note.markedForDeletion) throw new Error("can't delete note not marked for deletion")
    if (note.isDirty) throw new Error("can't delete note: isDirty")
    this.cache.delete(note.uniqueTag);
    this.noteChanged.fire({ note, type: "deleted" });
    await this.notes.delete(note.uniqueTag);
  }

  /**
   * Unpack note and put it in the global cache, emitting notification. This means,
   * unpacking new note is enough to get the system notified about it if it was not
   * already known to system.
   * @param isn stored (encrypted) note to unpack
   * @private
   */
  private async unpack(isn?: IStoredNote): Promise<MyoNote | undefined> {
    if (!isn) return undefined;
    const note: MyoNote = await MapSerializer.fromBoss(await this.#mainKey.etaDecrypt(isn.encryptedBody));
    if (note.uniqueTag != isn.uniqueTag)
      throw new Error("inconsistent stored note: uid differs");
    const d = isn.draftOf == "" ? undefined : isn.draftOf;
    if (note.draftOf != d)
      throw new Error(`inconsistent stored note: draftOf differs: ${note.draftOf}/${d}`);
    console.log("unpacked: " + note.title);
    if (!this.cache.has(note.uniqueTag)) {
      this.cache.set(note.uniqueTag, note);
      this.noteChanged.fire({ note: note, type: "updated" });
    }
    this.syncNoteFolders(note);
    return note;
  }

  private async pack(note: MyoNote): Promise<IStoredNote> {
    return {
      uniqueTag: note.uniqueTag,
      encryptedBody: await this.#mainKey.etaEncrypt(await MapSerializer.toBoss(note)),
      draftOf: note.draftOf ?? "",
      isDirty: note.isDirty ? "true" : "false"
    }
  }

  #cachedLastSerial?: number;

  async getLastNoteSerial(refresh = false): Promise<number> {
    if (this.#cachedLastSerial) return this.#cachedLastSerial;
    let savedLastSerial: number | undefined = await this.getParam("lastSerial");
    if (savedLastSerial == undefined || refresh) {
      console.log("looking for last serial");
      savedLastSerial = 0;
      for (const n of await this.allNotesEvent()) {
        if (n.serial && n.serial > savedLastSerial) savedLastSerial = n.serial;
      }
      console.log("last serial rescanned: " + savedLastSerial);
      await this.setParam("lastSerial", savedLastSerial);
    }
    this.#cachedLastSerial = savedLastSerial;
    return savedLastSerial;
  }

  async updateLastNoteSerial(note: MyoNote): Promise<void> {
    const value = note.serial;
    if (value) {
      if (!this.#cachedLastSerial) await this.getLastNoteSerial();
      // console.log(`rest serial ${this.#cachedLastSerial} / ${value}`)
      if (this.#cachedLastSerial! < value) {
        this.#cachedLastSerial = value;
        await this.setParam("lastSerial", this.#cachedLastSerial);
        console.log("updated last note serial: " + this.#cachedLastSerial);
      }
    }
  }

  #cachedParams = new Map<string, any>();

  async getParam<T>(name: string): Promise<T | undefined> {
    await this.ready;
    try {
      let result: T = this.#cachedParams.get(name);
      if (result) return result;
      const data = await this.params.get(name);
      // console.log(`getParam ${name} -> ${data}`);
      if (!data?.encryptedBody) return undefined;
      result = await MapSerializer.anyFromBoss<T>(await this.#mainKey.etaDecrypt(data.encryptedBody));
      this.#cachedParams.set(name, result);
      return result;
    } catch (e) {
      console.error(`decryption of db param ${name} failed`, e);
    }
    return undefined;
  }

  async setParam<T>(name: string, value: T): Promise<void> {
    await this.ready;
    this.#cachedParams.set(name, value);
    await this.params.put({
      encryptedBody: await this.#mainKey.etaEncrypt(await MapSerializer.toBoss(value)),
      name
    });
  }

  private noteChanged = new Emitter<MyoNoteEvent>();

  async allNotesEvent(): Promise<MyoNote[]> {
    await this.loadNotesFromDatabase();
    return this.allNotes.all
  }

  //   const result = Array<MyoNote>();
  //   for await(const n of this.all()) {
  //     result.push(n);
  //   }
  //   return result;
  // }

  readonly allNotes = new Folder<MyoNote>();
  readonly activeNotes = new Folder<MyoNote>();
  readonly deletedNotes = new Folder<MyoNote>();

  async loadNotesFromDatabase(): Promise<void> {
    await this.ready;
    let newNotes: IStoredNote[];
    do {
      newNotes = await this.notes.where("uniqueTag").noneOf([...this.cache.keys()]).limit(1).toArray();
      for (const isn of newNotes) await this.unpack(isn);
    } while (newNotes.length > 0)

  }

  async* all(): AsyncGenerator<MyoNote, void, unknown> {
    await this.ready;
    for (const x of this.cache.values()) {
      yield x;
    }
    let newNotes: IStoredNote[];
    do {
      newNotes = await this.notes.where("uniqueTag").noneOf([...this.cache.keys()]).limit(1).toArray();
      for (const isn of newNotes) {
        const note = await this.unpack(isn);
        if (note) {
          yield note;
        }
      }
    } while (newNotes.length > 0)
  }

  async* trashed(): AsyncGenerator<MyoNote> {
    for await (const n of this.all()) {
      if (n.trashed) yield n;
    }
  }

  // async* trashCan(): AsyncGenerator<MyoNote, void, unknown> {
  //   for await( const note of this.all() ) {
  //     if( note.trashedAt ) yield note;
  //   }
  // }

  subscribeAll(listener: (ev: MyoNoteEvent) => void): EmitterHandle {
    return this.noteChanged.addListener(listener);
  }

  //
  // subscribe(listener: (ev:MyoNoteEvent)=>void): EmitterHandle {
  //   const handle = this.noteChanged.addListener(listener);
  // }

  private readonly newNoteDraftId = "$$-- new note --$$";

  /**
   * Get existing draft for a given or new note, or create and save one.
   * @param forNote
   */
  async getOrCreateDraft(forNote: MyoNote | undefined = undefined): Promise<MyoNote | undefined> {
    let draft: MyoNote | undefined;
    draft = await this.getDraft(forNote?.uniqueId);
    if( draft ) return draft;

    // create new draft
    if( forNote )
      // for a note
      draft = new MyoNote( {...forNote, draftOf: forNote.uniqueTag});
    else
      // for generic note
      draft = new MyoNote({draftOf: this.newNoteDraftId});
    await this.saveNote(draft);
    return draft;
  }

  async deleteDraftFor(note: MyoNote | undefined): Promise<void> {
    const draft = await this.getDraft(note?.uniqueId);
    if( draft ) {
      console.log("deleting draft found for", draft.draftOf);
      draft.markedForDeletion = true;
      await this.deleteNote(draft)
      console.log("draft deleted:", draft.draftOf);
    }
    else
      console.log("draft for note not found", note?.uniqueTag ?? "new note");
  }

  async deleteDraftNote(note: MyoNote): Promise<void> {
    if( note.draftOf ) {
      console.log("directly deleting draft note", note.uniqueTag)
      note.markedForDeletion = true;
      await this.deleteNote(note);
      console.log("draft note directly deleted")
    }
    else
      console.warn("ignored attempt to delete draft which is not a draft", note);
  }
}
