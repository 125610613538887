import { utf8ToBytes } from "uparsecjs";

export class StorageValue {
  constructor(readonly storage: Storage, readonly name: string) {
  }

  get value(): string | null {
    return this.storage.getItem(this.name);
  }

  set value(newValue: string | null) {
    if (newValue)
      this.storage.setItem(this.name, newValue);
    else
      this.storage.clear()
  }
}

export class LocallyStoredValue extends StorageValue {
  constructor(name: string) {
    super(localStorage, name);
  }
}

export class SessionStoredValue extends StorageValue{
  constructor(name: string) {
    super(sessionStorage, name);
  }
}

export const z784: Uint8Array = new Uint8Array([
  105, 105, 101,  77,  54,  53, 102, 101,
  106,  90, 119, 116,  78, 118,  81,  90,
  57, 104,  56,  87, 101, 106,  81, 103,
  109, 118, 109,  52,  98, 100, 118, 113,
  84,  86,  76, 107, 114
]);
