







import { Component, Prop, Vue, Watch } from "vue-property-decorator";

const cryptoCharset = "125987*)qwopasdfghjklzxcvbnmQWERTKLZXCVBNM:.\"?=*+-¦|_ｦｱｳｴｵｶｷｹｺｻｼｽｾｿﾀﾂﾃﾅﾆﾇﾈﾊﾋﾎﾏﾐﾑﾒﾓﾔﾕﾗﾘﾜｲｸﾁﾄﾉﾌﾍﾖﾙﾚﾛﾝｳｵｹﾈﾎﾔﾍ日щыфж";

const minSequense = 9;
const maxSequence = 41;

function randomChar(): string {
  return cryptoCharset[~~(Math.random() * cryptoCharset.length)];
}

function randomIndex(array: any[]): number {
  return ~~(Math.random() * array.length);
}

function randomIn(min: number, max: number, divisor = 1): number {
  return ~~((Math.random() * (max - min) + min) / divisor);
}

@Component
export default class CryptoTitle extends Vue {
  @Prop() value!: string;
  @Prop() darker?: boolean;
  @Prop({ default: 1 }) speed!: number;

  mutatedValue: string = "???";

  counters: number[] = [];

  interval: any | null = null;

  get cryptoCharClass(): string {
    return "crypto-char" + (this.darker ? "2" : "");
  }

  fixSpace(s: string) {
    return s == ' ' ? "&nbsp;" : s;
  }

  resetSeries(text: string, short = false) {
    this.pauseCount = ~~(Math.random() * 47) + 17;
    this.counters = [];
    this.mutatedValue = "";
    if (this.speed < 0.001) {
      for (let i = 0; i < text.length; i++) {
        this.counters.push(0);
      }
      this.mutatedValue = this.value;
    } else {
      for (let i = 0; i < text.length; i++) {
        this.counters.push(randomIn(minSequense * this.speed, maxSequence * this.speed, short ? 2 : 1));
        this.mutatedValue += randomChar();
      }
    }
    if (this.interval == null)
      this.interval = setInterval(this.doStep, 70);
  }

  pauseCount = 0;

  doStep() {
    let changes = 0;
    this.mutatedValue = "";
    for (let i = 0; i < this.counters.length; i++) {
      if (this.counters[i] > 0) {
        this.counters[i]--;
        changes++;
        this.mutatedValue += randomChar();
      } else
        this.mutatedValue += this.value[i];
    }
    if (changes == 0) {
      if (this.pauseCount-- < 1) {
        let n = randomIn(1, 4);
        while (n-- > 0) {
          const i = randomIndex(this.counters)
          this.counters[i] = randomIn(minSequense, maxSequence, 2.2);
        }
        this.pauseCount = randomIn(27, 89);
      }
    }
  }


  @Watch('value', { immediate: true })
  onValueChanged(v: string) {
    this.resetSeries(v);
  }

  @Watch('speed', { immediate: true })
  onSpeedChanged(v: number) {
    if( this.value && this.value != "")
      this.resetSeries(this.value);
  }

  beforeDestroy() {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

}
