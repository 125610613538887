export interface ParsedUrlEntry {
  text: string;
  isUrl: boolean;
}

const reUrl = /https?:\/\/(www\.)?([-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b|localhost:\d+)([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g;

export function detectUrls(text: string): ParsedUrlEntry[] {
  const result = new Array<ParsedUrlEntry>();
  let position = 0;
  for (const m of text.matchAll(reUrl)) {
    if( m.index == undefined ) throw Error("unsupported matching engine, empty index");
    if (m.index > position)
      result.push({ text: text.slice(position, m.index), isUrl: false })
    result.push({text: m[0], isUrl: true});
    position = m.index + m[0].length;
  }
  if( position < text.length)
      result.push({ text: text.slice(position), isUrl: false })

  return result;
}
