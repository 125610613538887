









import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PropLine extends Vue{
  @Prop() name!: string;
}
