
















import { Component, Vue } from "vue-property-decorator";
import LoggedIn from "../components/LoggedIn.vue";
import MenuLayout from "../components/MenuLayout.vue";
import PropLine from "@/components/PropLine.vue";
import AsyncLine from "@/components/AsyncLine.vue";
@Component({
  components: { AsyncLine, PropLine, MenuLayout, LoggedIn }
})
export default class Settings extends Vue {

  plan: string | null = null;
  freeSpace: number | null = null;
  usedSpace: number | null = null;

}
