// import * as Unicrypto from "unicrypto";
// Unicrypto.setup({ libraryPath: "./bb" })

import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { BIcon } from "bootstrap-vue";
import { encode64, unicryptoReady } from "unicrypto";
import { sha256 } from "uparsecjs";

unicryptoReady.then(()=>console.log("unicrypto is ready"));

Vue.config.productionTip = false

// CryptoWorker.setup({
//   libAbsolute: document.location.origin + '/js/crypto.v1.8.4.js',
//   wasmAbsolute: document.location.origin + '/crypto.v1.8.4.wasm'
// });

// console.log({
//   libAbsolute: document.location.origin + '/js/crypto.v1.8.4.js',
//   wasmAbsolute: document.location.origin + '/js/crypto.v1.8.4.wasm'
// });

const requireComponent = require.context(
  // The relative path of the components folder
  './components',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /\w+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // we use camel-case file names:
  const componentName = fileName.split('/')?.pop()?.replace(/\.\w+$/, '');
  if( componentName ) {
    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    );
  }
});

Vue.component("BIcon",BIcon);

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

unicryptoReady.then(async () => {
  console.log("unicrypto reports ready");
  console.log("unicrypto works? "+encode64(await sha256("test")));
})
