import { ConsentDoc } from "@/docs/ConsentDoc";
import { LegalIds } from "@/docs/LegalIds";

const product = LegalIds.product;

export const PPOL_doc = new ConsentDoc({
  version: 2,
  name: "Privacy Policy",
  id: "PPOL",
  text: `

# ${product} Privacy Policy

## Our Philosophy

${product} service (“service” ,“we”, “us”, “our”) are committed to protecting and respecting your privacy by handling all Personal Information securely and with care in accordance with the principle of extreme privacy, confidentiality and anonymity.  

This Privacy Policy explains how ${product} collects, uses and discloses your Personal Information and how to contact ${product} if you have any further questions regarding our management of your personal information. Please read this policy carefully to ensure you understand ${product}’s practices regarding the management of any personal information.

## What is ‘Personal Information’?

‘Personal Information’ is any information or an opinion about you that identifies you or that could reasonably identify you. This could include information such as your name, contact details, etc.

### Scope

This Privacy Policy applies to information, including Personal Information, that ${product} collects through your access of ${product}’s website and the use of ZeNote’s Products and Services.

If you use ${product}’s Products and Services, as outlined in the Beta Participation Agreement or any comparable agreement, you have also agreed to this Privacy Policy. The terms “we”, “us”, “our,” or “${product}” includes ${product} and our affiliates. It explains how we may collect, use, and disclose information we obtain through out Products and Services.

## Purposes for collecting information

${product} __does not collect personal information__, but stores your data in heavily encrypted form to allow offline access to your data. The only information available to the service is a hashed login name that we encourage you to select as most meaningless and disclosing no information about you. Hashed means that we service stores tha cryptographic hash of the transfomed login name. This is a one-way transformation, though brute-force dictionary based attack may sometimes discover login names that fits the hash, but with no guarantees. The login hash does not therefore identify user personality and only used to distinguish users to allow access to secure authentication protocol, to access encrypted data in the ${product} cloud.

## How we use and disclose your information

${product} does not use your Personal Information except the login, needed to access your cloud data. All the information you enter in the system is processed only locally in your browser (also in offline mode), and encrypted with a key derived from the password you enter on the page. All your data ere then end to end encrypted and stored locally to allow offline access, and sent to the cloud in encrypted form.

__The service has no access to your encryption keys__. It means, that unless you disclose your password, servie has absolutely no access to your data, and, therefore, service is not collecting any of your personal information.

### End-to-end encryption
   
This term means that the information you enter in ${product} is oncly decrypted locally when you use it, but is stored both locally and in the cloud in heavily encrypted form, and, unless you activate "Remember Me" feature we discourage you to use, your keys will not be available even in the storage of your device you access the service from. And in no case your keys are not available to the service or transmitted in decryptable form over the internet. We use the following NO-rules set:

__NO Personal Data is available to the service__. It means, that even in unlikely event of our servers been compromised, stolen or confiscated, the service would not be able to disclose your data.

__NO Personal Data is stored on your device__. The data are locally encrypted using several keys derived from your password, and the data in the local storage are heavily encrypted and could not be occasionally disclosed, for example, with a computer virus copying your files, unless you use "Remember me" feature we recommend to refrain of.

__NO personal data is transmitted over the network__. Any data we transfer are encrypted, and we have no keys to access it.

__NO password could be recovered__. This is actually a strong point: if the service could recover password, it is a security breach. We did not leave any, so it you loose the password, you loose all of your data. This also means that nobody could thrft your data by impersonate you to the service to get the password recovery, say, via hijacked email or phone.

More information: ${LegalIds.root}/show_doc/EENCR     

## Cookies

Cookies are small text files which are downloaded to your browser when you visit a website. We do not use cookies.

## Analytics

We do not use analytics. No, we don't. For sure. Sorry google, our client's privacy our first priority.

## Security

We will take reasonable and unreasonable steps to store information in a secure manner. We use slow and robust key derivation algorithms and many different keys of different types to safeguard access to your data. None of these are available to the service and none of them are ever transmitted out of your device. For example, we use 2 keys, derived from your password, a high strength RSA key to unlock access to encrypted data, separate keys to encrypt data in the cloud, to encrypt the local storage in browser storages, etc. 
 
Despite our reasonable efforts, we cannot guarantee that the security of your Personal Information will not be breached, for example, if you will use predictable or weak password, or it will be stolen using a camera or otherwise disclosed. Therefore, to the fullest extent permitted by law, we disclaim all liability any responsibility for any damage you may suffer due to any loss, unauthorised access, misuse or alteration of any information we collect, except to the extent that our liability cannot be excluded by applicable laws and regulations which we are subject to.

You can confirm that any ${product} data-entry page uses encryption by checking that:

- the page address in the Web browser’s toolbar or status bar begins with https://; or

- the padlock icon in the web browser’s toolbar or status bar is locked.

The service know no email of its users and therefore may not and will not contact them by email.

## Deletion, retention and portability of personal information

If, at any point in time, you wish for your Personal Information to be destroyed; or be de-identified; or have access 
to; or a copy to be provided to you, you can do it using software user interface. Note that some functions may not
be available during the beta phase.

## Changes to this privacy policy

This Privacy Policy discloses our current privacy practices. From time to time and in line with client expectations and legislative changes, this Privacy Policy will be reviewed, and, if appropriate, updated at any time without notice. Any changes to this Privacy Policy that may be made from time to time will be available on ${product} website.

## Complaints

If you have a complaint about the way we have dealt with your Personal Information we invite you to provide written details of your complaint to our Privacy Officer using a form available in the main application menu (may not be available during beta phase). 

Our Privacy Officer will contact you within 14 days of the date we receive the written details of your compliant to acknowledge that we have received it. Our Privacy Officer will then:

- review the way we dealt with your Personal Information;

- conduct an internal investigation (if necessary) into how your Personal Information came to be handled in the way that it was; and

- provide a report to you within one month of the date we acknowledged receipt of your complaint.

--------------------------------

_Last Modified: September 2021._
`
});
