/**
 * Assuming the array is sorted, find max index of an element equal or smaller than `value`. E.g. it there is at least
 * one element equal to value, index of one of them will be returned. If not, the index of maximum element not exceeding
 * `value` will be returned. If all elements are _bigger_ then it returns -1.
 *
 * In partocular, inserting `value` item at the _next index_ (which could be outside bounds
 * but its ok for javascript) will keep the array sorted.
 *
 * @param array to scan, must be sorted
 * @param value to look for
 * @param comparer function to compare items
 * @return index if biggest element that is equal or less than value, or -1 if there are no elements or all of them
 *               are bigger than value
 */
export function biggestSmallerIndex<T>(array: T[], value: T, comparer: (a: T, b: T)=> number): number {
  function find(l: number, r: number): number {
    if( l>=r) return l;
    const m = Math.floor((l+r)/2);
    const cr = comparer(array[m],value);
    if( cr === 0 ) return m;
    return cr < 0 ? find(l,m-1) : find(m+1,r);
  }
  if( array.length == 0 || comparer(array[0], value) < 0 ) return -1;
  const result = find(0, array.length);
  return result >= array.length ? result-1 : result;
}

export function insertIntoSortedArray<T>(array: T[], value: T, comparer: (a:T,b:T) => number): T[] {
  array.splice(biggestSmallerIndex(array, value,comparer)+1, 0, value);
  return array;
}
