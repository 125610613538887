






















import { Component, Vue } from "vue-property-decorator";
import { Notes } from "@/myonly.notes/Notes";
import { Passwords } from "uparsecjs";
import { errorToast, infoToast } from "@/lib/toasts";
import { ifLocalTest } from "@/lib/tools";
import WaitOverlay from "@/components/WaitOverlay.vue";
import Consent from "@/components/Consent.vue";

@Component({
  components: { Consent, WaitOverlay }
})
export default class Register extends Vue {
  login = ifLocalTest("notest_1");
  loginState: boolean | null = null
  password1 = ifLocalTest("12345qwert!");
  password1Message = "";
  password2 = ifLocalTest("12345qwert!");;

  rememberMe = false;
  loading = false;

  progressMessage = "";

  get password1State(): boolean | null {
    if( this.password1 == "") return null;
    const strength = Math.floor(Passwords.estimateBitStrength(this.password1));
    const ok = strength >= 110;
    this.password1Message = (!ok ? "Too simple" : "Good") + ` (~${strength} bits)`;
    return ok;
  }

  get password2State(): boolean | null {
    if( this.password1 == "") return null;
    return this.password1 == this.password2;
  }

  async loginChanged() {
    this.loginState = null;
    const result = await Notes.checkLoginAvailable(this.login);
    console.log("rr", result);
    this.loginState = result;
    // TODO: check name is OK and set loginState
  }

  async register() {
    try {
      this.loading = true;
      switch(await Notes.signUp(
          this.login,
          this.password1,
          this.rememberMe,
          (text:string)=> this.progressMessage=text )) {
        case "error":
          errorToast(this, "Network error, please try again later");
          break;
        case "login_in_use":
          this.loginState = false;
          break;
        case "OK":
          infoToast( this,"Successful registration", "Success");
          break;
      }
    }
    catch(e1) {
      const e = e1 as any;
      errorToast(this,""+e.message);
    }
    finally {
      this.loading = false;
    }
  }
}
