import { Emitter, EmitterEventListener } from "uparsecjs/dist/Emitter";
import { EmitterHandle } from "uparsecjs";

export interface FolderItemEvent<T> {
  type: "changed" | "added" | "deleted";
  item: T;
}

/**
 * Item suitable to be processed by [[Folder]]. To make it possible, the item should provide unique string Id and
 * function to detect item content change, to report item, mutations.
 */
export interface FolderItem {
  /**
   * Unique string identifying the item, once created should never change
   */
  get uniqueId(): string;

  /**
   * Comare two states of the same object. Should a;ways return false if other.uniqueId is not same as this.uniqueId,
   * otherwise check contents.
   * @param other item to cimpare
   * @return true if other is equal to this, false otherwise.
   */
  equalsTo<T extends FolderItem>(other: T): boolean;
}

/**
 * Smart folder for arbitrary items.
 */
export class Folder<T extends FolderItem> implements Iterable<T> {
  private itemEvent = new Emitter<FolderItemEvent<T>>();

  private items = new Map<string, T>();

  /**
   * Add listener to folder item events. If the folder is not empty, the listener will immediately be added for
   * each of then with "add" event,m unless specified otherwise
   * @param lr listener to add
   * @param invokeForExistingItems call the listener for items already in the folder
   * @return event handle, for example, to unsubscribe.
   */
  addListener(lr: EmitterEventListener<FolderItemEvent<T>>,invokeForExistingItems: boolean=true): EmitterHandle {
    for( const i of this.items.values()) lr({type: "added", item: i});
    return this.itemEvent.addListener(lr);
  }

  /**
   * Smart add content to folder. Performs add or update, or no action. Fires corresponding event.
   * @param item to add/update
   * @return operation result.
   */
  addItem(item: T): "added" | "updated" | "exists" {
    const id = item.uniqueId;
    const existing = this.items.get(id);
    if( existing !== undefined ) {
      if( existing.equalsTo(item) ) return "exists";
      this.items.set(id, item);
      this.itemEvent.fire({item, type: "changed"});
      return "updated";
    }
    this.items.set(id, item);
    this.itemEvent.fire({item, type:"added"});
    return "added";
  }

  /**
   * Smart remove. If the item was in the folder, removes it and fores event. Else does nothing;
   * @param item to remove
   * @return true if the item was removed and false if the item was not found in the folder.
   */
  removeItem(item: T): boolean {
    if( this.items.delete(item.uniqueId) ) {
      this.itemEvent.fire({item,type:"deleted"});
      return true;
    }
    return false;
  }

  clear() {
    for( const item of this.items.values()) this.itemEvent.fire({item,type: "deleted"});
    this.items.clear();
  }

  [Symbol.iterator](): Iterator<T> {
    return this.items.values();
  }

  get all(): T[] {
    return new Array(...this);
  }
}
